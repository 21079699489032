import React, { Fragment } from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGCarouselComponent } from "../common/dgdgcarousel/DGDGCarouselComponent";
import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormCheckboxV2Component } from "../common/dgdgcheckbox/DGDGFormCheckboxV2Component";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormTextAreaComponent } from "../common/dgdgtextarea/DGDGFormTextAreaComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

import { UsedReconditioningInventoryDesktopComponent } from "./UsedReconditioningInventoryDesktopComponent";
import { UsedReconditioningInventoryMobileComponent } from "./UsedReconditioningInventoryMobileComponent";
import { UsedReconditioningInventoryQueryComponent } from "./UsedReconditioningInventoryQueryComponent";

export class UsedReconditioningInventoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            selectedStore: null,
            tableTitle: "",
            tableData: [],
            paintTable: false,
            undoDetail: null,
            actionName: null,
            serviceOutData: null,
            bodyOutData: null,
            detailOutData: null,
            reconOutData: null,
            photoOutData: null,
            serviceActiveCount: "",
            bodyActiveCount: "",
            paintActiveCount: "",
            detailActiveCount: "",
            reconActiveCount: "",
            photoActiveCount: "",
            wholesaleRow: null,
            showWholesaleConfirmation: false,
            cpoRow: null,
            showCPOConfirmation: false,
            iframeURL: "",
            stockNumber: "",
            vin: "",
            legendText: null,

            showToggle: false,
            toggleId: null,
            editTop: null,
            editLeft: null,
            toggleValue: false,
            showPublishConfirmation: false,
            publishRow: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        this.onWholesaleSelectChange = this.onWholesaleSelectChange.bind(this);
        this.onOkWholesaleChange = this.onOkWholesaleChange.bind(this);
        this.onCancelWholesaleClick = this.onCancelWholesaleClick.bind(this);

        this.onCPOSelectChange = this.onCPOSelectChange.bind(this);
        this.onOkCPOChange = this.onOkCPOChange.bind(this);
        this.onCancelCPOClick = this.onCancelCPOClick.bind(this);

        this.onServiceOutCarousalCallback = this.onServiceOutCarousalCallback.bind(this);
        this.onServiceOutColumnCallback = this.onServiceOutColumnCallback.bind(this);
        this.onSmogHoldChange = this.onSmogHoldChange.bind(this);
        this.onVinHoldChange = this.onVinHoldChange.bind(this);
        this.onWeightHoldChange = this.onWeightHoldChange.bind(this);
        this.onServiceHoldChange = this.onServiceHoldChange.bind(this);
        this.onServiceOutChange = this.onServiceOutChange.bind(this);
        this.onServiceOutDateChange = this.onServiceOutDateChange.bind(this);
        this.onOkServiceOut = this.onOkServiceOut.bind(this);

        this.onBodyOutCarousalCallback = this.onBodyOutCarousalCallback.bind(this);
        this.onBodyOutColumnCallback = this.onBodyOutColumnCallback.bind(this);
        this.onBodyOutClick = this.onBodyOutClick.bind(this);
        this.onBodyOutChange = this.onBodyOutChange.bind(this);
        this.onOkBodyOut = this.onOkBodyOut.bind(this);

        this.onDetailOutCarousalCallback = this.onDetailOutCarousalCallback.bind(this);
        this.onDetailOutColumnCallback = this.onDetailOutColumnCallback.bind(this);
        this.onDetailOutClick = this.onDetailOutClick.bind(this);
        this.onDetailOutChange = this.onDetailOutChange.bind(this);
        this.onOkDetailOut = this.onOkDetailOut.bind(this);

        this.onReconOutCarousalCallback = this.onReconOutCarousalCallback.bind(this);
        this.onReconOutColumnCallback = this.onReconOutColumnCallback.bind(this);
        this.onReconInteriorOnlyChange = this.onReconInteriorOnlyChange.bind(this);
        this.onReconOutClick = this.onReconOutClick.bind(this);
        this.onReconOutChange = this.onReconOutChange.bind(this);
        this.onOkReconOut = this.onOkReconOut.bind(this);
        this.onPaintHoldChange = this.onPaintHoldChange.bind(this);

        this.onPhotoOutCarousalCallback = this.onPhotoOutCarousalCallback.bind(this);
        this.onPhotoOutColumnCallback = this.onPhotoOutColumnCallback.bind(this);
        this.onPhotoOutClick = this.onPhotoOutClick.bind(this);
        this.onPhotoOutChange = this.onPhotoOutChange.bind(this);
        this.onOkPhotoOut = this.onOkPhotoOut.bind(this);

        this.onUndoInventoryClick = this.onUndoInventoryClick.bind(this);
        this.onUndoOkClick = this.onUndoOkClick.bind(this);
        this.onCancelUndoClick = this.onCancelUndoClick.bind(this);

        this.onAddCommentColumnCallback = this.onAddCommentColumnCallback.bind(this);
        this.onToggleViewComments = this.onToggleViewComments.bind(this);
        this.onToggleAddComments = this.onToggleAddComments.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onSaveCommentClick = this.onSaveCommentClick.bind(this);

        this.onCancelDateChange = this.onCancelDateChange.bind(this);
        this.roNumberColumnCallback = this.roNumberColumnCallback.bind(this);

        this.onPublishSelectChange = this.onPublishSelectChange.bind(this);
        this.onOkPublishChange = this.onOkPublishChange.bind(this);
        this.onCancelPublishClick = this.onCancelPublishClick.bind(this);

        this.onEstCompletionDateColumnCallback = this.onEstCompletionDateColumnCallback.bind(this);
        this.onEstCompletionDateChange = this.onEstCompletionDateChange.bind(this);
        this.onSaveEstCompletionDateClick = this.onSaveEstCompletionDateClick.bind(this);

        this.onSortByChange = this.onSortByChange.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("UsedReconditioningInventoryComponent - componentDidMount");
            this.getLegendText();
            CommonService.clientAzureStorageLog("UsedReconditioningInventoryComponent", "componentDidMount", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Used Car Prep";
    startDate = "01/01/2020";
    endDate = moment().format('MM/DD/YYYY');
    serviceHoldList = [
        { key: "PendingArrival", value: "Pending Arrival" },
        { key: "Dispatch", value: "Holding Dispatch" },
        { key: "PricingAvailability", value: "Holding Pricing & Availability" },
        { key: "PartsOrder", value: "Holding Parts Order" },
        { key: "Parts", value: "Holding Parts Arrival" },
        { key: "BackOrderParts", value: "Holding Back Order Parts" },
        { key: "Monitors", value: "Monitors" },
        { key: "AtOEMDealer", value: "At OEM Dealer" },
        { key: "Vendor", value: "Holding Vendor" },
        { key: "Authorization", value: "Holding Authorization" },
        { key: "PendingTransport", value: "Pending Transport" },
        { key: "Other", value: "Other" },
        { key: "NoHolds", value: "No Holds" }
    ];

    configureData(response, selectedStore) {
        let today = moment();
        response.forEach((responseItem, index) => {
            responseItem.ok_publish = responseItem.inventory_state === "InPrep" ? true : false;
            responseItem.service_out_due_age = responseItem.entry_date ? today.diff(responseItem.entry_date, 'days') : 0;
            responseItem.body_out_due_age = responseItem.service_out_date_time ? today.diff(responseItem.service_out_date_time, 'days') : 0;
            responseItem.detail_out_due_age = responseItem.body_out_date_time ? today.diff(responseItem.body_out_date_time, 'days') : responseItem.service_out_date_time ? today.diff(responseItem.service_out_date_time, 'days') : 0;
            responseItem.recon_out_due_age = responseItem.detail_out_date_time ? today.diff(responseItem.detail_out_date_time, 'days') : 0;
            responseItem.photo_out_due_age = responseItem.recon_out_date_time ? today.diff(responseItem.recon_out_date_time, 'days') : 0;
            responseItem.rowCssName = (responseItem.is_wholesale || responseItem.ok_publish) ? "dgdg-used-reconditioning-bg-gray" : (responseItem.is_smog_hold || responseItem.is_vin_hold || responseItem.is_weight_hold || (responseItem.service_hold_metric_name && responseItem.service_hold_metric_name !== "NoHolds")) ? "dgdg-used-reconditioning-bg-orange" : null;
            responseItem.color = responseItem.rowCssName === "dgdg-used-reconditioning-bg-gray" ? "Grey" : responseItem.rowCssName === "dgdg-used-reconditioning-bg-orange" ? "Orange" : "White";
            responseItem.est_completion_date = responseItem.est_completion_date ? moment(responseItem.est_completion_date) : null;
            responseItem.est_completion_date_cellCssName = (responseItem.est_completion_date && responseItem.est_completion_date < moment().subtract(1, "day")) ? "dgdg-used-reconditioning-estimated-date" : "";
            responseItem.metric_name = responseItem.service_hold_metric_name ? this.serviceHoldList.filter((service) => service.key === responseItem.service_hold_metric_name)[0]?.value : "Select Service Hold";
            responseItem.service_hold_metric_name_sort = !responseItem.service_out_date_time && responseItem.service_hold_metric_name ? responseItem.metric_name : "";
            responseItem.disable_wholesale = responseItem.edit_wholesale === 1 ? false : true;
            responseItem.disable_publish = !responseItem.edit_publish;
            responseItem.wholesale_label = "Retl";
            responseItem.wholesale_label2 = "Whsl";
            responseItem.cpo_label = "Not <br /> CPO";
            responseItem.cpo_label2 = "CPO";
            responseItem.ok_publish_label = "OK to <br /> Publish";
            responseItem.ok_publish_label2 = "Do Not <br /> Publish";
        });
        this.setState({
            isLoadingData: false,
            tableTitle: selectedStore === null ? "All Stores" : selectedStore.display_name,
            tableData: CommonService.addOrdinalColumn(response),
            paintTable: true,
            serviceActiveCount: response[0].service_active_count,
            bodyActiveCount: response[0].body_active_count,
            paintActiveCount: response[0].paint_active_count,
            detailActiveCount: response[0].detail_active_count,
            reconActiveCount: response[0].recon_active_count,
            photoActiveCount: response[0].photo_active_count
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    getComments(comments) {
        let htmlContent = [];
        if (comments !== null) {
            let commentParts = [];
            comments.split('\r').forEach(function (value) {
                commentParts.push(value.split(': '));
            });
            commentParts.forEach((commentPart) => {
                if (commentPart[1]) {
                    htmlContent.push(
                        <Fragment>
                            <span key="comment_part1" style={{ "color": "blue" }}>
                                {(commentPart[0] !== "" ? commentPart[0] + ": " : null)}
                            </span>
                            <span key="comment_part2">
                                {(commentPart[1])}
                            </span>
                        </Fragment>);
                    htmlContent.push(<br />);
                }
            });
        }

        return htmlContent;
    }

    getLegendText() {
        let legendText = <Fragment>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-used-reconditioning-bg-gray" />&nbsp;&nbsp;&nbsp; Wholesale/Do Not Publish</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-used-reconditioning-bg-orange" />&nbsp;&nbsp;&nbsp;Pending Smog, VIN, Weight, etc</div>
            <div className="d-inline-block text-nowrap"><div className="d-inline dgdg-import-wizard-legend dgdg-used-reconditioning-estimated-date" />&nbsp;&nbsp;&nbsp;Past EDOC</div>
        </Fragment>
        this.setState({ legendText: legendText })
    }

    onEstCompletionDateColumnCallback(rowData) {
        let minDate = "today";
        let disabledDates = [];
        if (rowData.est_completion_date && rowData.est_completion_date < moment().subtract(1, "day")) {
            minDate = moment(rowData.est_completion_date).format('MM/DD/YYYY');
            let currentDate = moment(rowData.est_completion_date).add(1, "day");
            while (currentDate < moment().subtract(1, "day")) {
                disabledDates.push(currentDate.format('M/D/YYYY'));
                currentDate = currentDate.add(1, "day");
            }
            rowData.est_completion_date_cellCssName = "dgdg-used-reconditioning-estimated-date";
        }
        else {
            rowData.est_completion_date_cellCssName = "";
        }

        let isDateInvalidorEmpty = !CommonService.isDateValid(rowData.est_completion_date);

        return <Fragment>
            <div className="d-inline-flex">
                <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id={rowData.dgdg_column_ordinal + "_estColumn"} value={rowData.est_completion_date} minDate={minDate} onDateChange={this.onEstCompletionDateChange} additionalData={rowData} disabledDates={disabledDates} />
                <button type="button" className="btn btn-link dgdg-font-size-18" disabled={isDateInvalidorEmpty} onClick={(event) => this.onSaveEstCompletionDateClick(event, rowData)}>
                    <span className="fas fa-save" />
                </button>
            </div>
            <br />
            <span> {rowData.est_completion_by ? ("Updated by: " + rowData.est_completion_by) : ""} </span>
        </Fragment>
    }

    onEstCompletionDateChange(id, value, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.est_completion_date = value ? moment(value) : null;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveService(inputData, rowData, method) {
        let tableData = this.state.tableData;
        try {
            rowData.service_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let url = "";
            if (method === "In")
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            return fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let serviceActiveCount = this.state.serviceActiveCount;
                    if (inputData.outDateTime) {
                        serviceActiveCount--;
                    }
                    else {
                        serviceActiveCount++;
                    }

                    if (method === "In") {
                        rowData.service_in_date_time = inputData.inDateTime;
                    }
                    else {
                        rowData.service_out_date_time = inputData.outDateTime;
                        rowData.service_out_by = inputData.outDateTime ? this.props.remoteUpn : null;
                        rowData.can_undo_service_out_date_time = inputData.outDateTime ? true : false;
                    }
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        serviceActiveCount: serviceActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    this.onCancelDateChange();
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveBody(inputData, rowData, method) {
        let tableData = this.state.tableData;
        try {
            rowData.body_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let url = "";
            if (method === "In")
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            return fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let detailActiveCount = this.state.detailActiveCount;
                    let bodyActiveCount = this.state.bodyActiveCount;
                    if (inputData.outDateTime) {
                        bodyActiveCount--;
                        detailActiveCount++;
                    }
                    else {
                        bodyActiveCount++;
                        detailActiveCount--;
                    }

                    if (method === "In") {
                        rowData.body_in_date_time = inputData.inDateTime;
                    }
                    else {
                        rowData.body_out_date_time = inputData.outDateTime;
                        rowData.body_out_by = inputData.outDateTime ? this.props.remoteUpn : null;
                        rowData.can_undo_body_out_date_time = inputData.outDateTime ? true : false
                        rowData.can_undo_service_out_date_time = inputData.outDateTime ? false : true;
                    }
                    rowData.body_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        bodyActiveCount: bodyActiveCount,
                        detailActiveCount: detailActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    this.onCancelDateChange();
                })
                .catch(notOKResponse => {
                    rowData.body_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveDetail(inputData, rowData, method) {
        let tableData = this.state.tableData;
        try {
            rowData.detail_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let url = "";
            if (method === "In")
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            return fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let detailActiveCount = this.state.detailActiveCount;
                    let reconActiveCount = this.state.reconActiveCount;
                    if (inputData.outDateTime) {
                        detailActiveCount--;
                        reconActiveCount++;
                    }
                    else {
                        detailActiveCount++;
                        reconActiveCount--;
                    }

                    if (method === "In") {
                        rowData.detail_in_date_time = inputData.inDateTime;
                    }
                    else {
                        rowData.detail_out_date_time = inputData.outDateTime;
                        rowData.detail_out_by = inputData.outDateTime ? this.props.remoteUpn : null;
                        rowData.can_undo_service_out_date_time = !rowData.body_in_date_time && !rowData.body_out_date_time ? true : false;
                        rowData.can_undo_detail_out_date_time = inputData.outDateTime ? true : false;
                        rowData.can_undo_body_out_date_time = inputData.outDateTime ? false : true;
                    }
                    rowData.detail_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        detailActiveCount: detailActiveCount,
                        reconActiveCount: reconActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    this.onCancelDateChange();
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    saveRecon(inputData, rowData, method) {
        let tableData = this.state.tableData;
        try {
            rowData.recon_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let url = "";
            if (method === "In")
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            return fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let reconActiveCount = this.state.reconActiveCount;
                    let photoActiveCount = this.state.photoActiveCount;
                    if (inputData.outDateTime) {
                        reconActiveCount--;
                        photoActiveCount++;
                    }
                    else {
                        reconActiveCount++;
                        photoActiveCount--;
                    }

                    if (method === "In") {
                        rowData.recon_in_date_time = inputData.inDateTime;
                    }
                    else {
                        rowData.recon_out_date_time = inputData.outDateTime;
                        rowData.recon_out_by = inputData.outDateTime ? this.props.remoteUpn : null;
                        rowData.can_undo_service_out_date_time = false;
                        rowData.can_undo_detail_out_date_time = inputData.outDateTime ? false : true;
                        rowData.can_undo_recon_out_date_time = inputData.outDateTime ? true : false;
                    }
                    rowData.recon_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        reconActiveCount: reconActiveCount,
                        photoActiveCount: photoActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    this.onCancelDateChange();
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    savePhoto(inputData, rowData, method) {
        let tableData = this.state.tableData;
        try {
            rowData.photo_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let url = "";
            if (method === "In")
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            return fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let photoActiveCount = this.state.photoActiveCount;
                    if (inputData.outDateTime) {
                        photoActiveCount--;
                    }
                    else {
                        photoActiveCount++;
                    }
                    if (method === "In") {
                        rowData.photo_in_date_time = inputData.inDateTime;
                    }
                    else {
                        rowData.photo_out_date_time = inputData.outDateTime;
                        rowData.photo_out_by = inputData.outDateTime ? this.props.remoteUpn : null;
                        rowData.can_undo_service_out_date_time = false;
                        rowData.can_undo_detail_out_date_time = false;
                        rowData.can_undo_recon_out_date_time = inputData.outDateTime ? false : true;
                        rowData.can_undo_photo_out_date_time = inputData.outDateTime ? true : false;
                    }
                    rowData.photo_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        photoActiveCount: photoActiveCount,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    this.onCancelDateChange();
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveEstCompletionDateClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.est_completion_date_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = JSON.stringify({
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                estimatedCompletionDate: rowData.est_completion_date ? CommonService.formatDate(rowData.est_completion_date) : null
            });
            fetch("/UsedReconditioning/SaveEstimatedCompletionDate", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.est_completion_by = this.props.remoteUpn;
                    rowData.est_completion_date_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.est_completion_date_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetReportClick(selectedStore) {
        try {
            this.setState({
                isLoadingData: true,
                selectedStore: selectedStore
            });
            let inputData = JSON.stringify({
                storeNumber: selectedStore ? selectedStore.store_number : null
            });
            fetch("/UsedReconditioning/GetReport", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(parsedResponse, selectedStore);
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("UsedReconidioningInvetoryComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onWholesaleSelectChange(id, isChecked, rowData) {
        try {
            rowData.is_wholesale = isChecked;
            this.setState({
                wholesaleRow: rowData,
                showWholesaleConfirmation: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkWholesaleChange() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.wholesaleRow.stock_number)[0];
        try {
            rowData.is_wholesale = this.state.wholesaleRow.is_wholesale;
            rowData.is_wholesale_showSpinner = true;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({
                    paintTable: false,
                    showWholesaleConfirmation: false
                });
            });

            let inputData = JSON.stringify({
                stockNumber: this.state.wholesaleRow.stock_number,
                isWholesale: this.state.wholesaleRow.is_wholesale
            });
            fetch("/UsedReconditioning/SaveWholesale", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.is_wholesale_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_wholesale_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelWholesaleClick() {
        if (this.state.showWholesaleConfirmation) {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === this.state.wholesaleRow.stock_number)[0];
            rowData.is_wholesale = this.state.wholesaleRow.is_wholesale ? false : true;
            try {
                this.setState({
                    tableData: tableData,
                    showWholesaleConfirmation: false
                });
            }
            catch (error) {
                console.devError(error, null, this.props.applicationInsights);
            }
        }
    }

    onCPOSelectChange(id, isChecked, rowData) {
        try {
            rowData.is_cpo = isChecked;
            this.setState({
                cpoRow: rowData,
                showCPOConfirmation: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkCPOChange() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.cpoRow.stock_number)[0];
        try {
            rowData.is_cpo = this.state.cpoRow.is_cpo;
            rowData.is_cpo_showSpinner = true;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({
                    paintTable: false,
                    showCPOConfirmation: false
                });
            });

            let inputData = JSON.stringify({
                stockNumber: this.state.cpoRow.stock_number,
                isCPO: this.state.cpoRow.is_cpo
            });
            fetch("/UsedReconditioning/SaveCPO", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.is_cpo_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_cpo_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelCPOClick() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.cpoRow.stock_number)[0];
        rowData.is_cpo = this.state.cpoRow.is_cpo ? false : true;
        try {
            this.setState({
                tableData: tableData,
                showCPOConfirmation: false,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onServiceOutCarousalCallback(rowData) {
        let text = "Pending";
        let status = "pending";
        let statusOut = "";
        if (rowData.service_out_date_time) {
            text = "Completed";
            status = "completed";
            if (rowData.body_out_date_time || rowData.detail_in_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusOut={statusOut} />;
    }

    onServiceOutColumnCallback(rowData) {
        let rowId = "serviceOutDate_" + rowData.stock_number;
        if (rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.service_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + rowData.service_out_age + " days since Entry)"}
                    <br />
                    {"By " + rowData.service_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id={rowId} disabled={rowData.is_wholesale} value={rowData.service_out_date_time} hideTextbox onDateChange={this.onServiceOutDateChange} minDate={this.startDate} maxDate={this.endDate} cssName="dgdg-used-reconditioning-calendar-icon" buttonCssName="dgdg-used-reconditioning-calendar-button" isHidden />
                {
                    rowData.can_undo_service_out_date_time
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Service Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let disabled = rowData.edit_service === 0 || (rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold);
            let buttonDisabled = rowData.is_wholesale || disabled || rowData.is_parts_hold || rowData.is_smog_hold || rowData.is_vin_hold || rowData.is_weight_hold;
            let CssName = "btn btn-success";
            if (rowData.service_out_due_age >= 4)
                CssName = "btn btn-danger";
            else if (buttonDisabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="form-inline dgdg-used-reconditioning-service-out">
                    <DGDGFormCheckboxV2Component label="Smog:" disabled={rowData.is_wholesale || disabled} id={rowId + "_smogHold"} value={rowData.is_smog_hold} onChange={this.onSmogHoldChange} />
                    &nbsp;&nbsp;
                    <DGDGFormCheckboxV2Component label="VIN:" cssName="dgdg-used-reconditioning-body-hold-toggle" disabled={rowData.is_wholesale || disabled} id={rowId + "_vinHold"} value={rowData.is_vin_hold} onChange={this.onVinHoldChange} />
                    &nbsp;&nbsp;
                    <DGDGFormCheckboxV2Component label="Weight:" cssName="dgdg-used-reconditioning-body-hold-toggle" disabled={rowData.is_wholesale || disabled} id={rowId + "_weightHold"} value={rowData.is_weight_hold} onChange={this.onWeightHoldChange} />
                </div>
                <div className="form-inline" style={{ "marginBottom": "5px" }}>
                    <DGDGFormDropdownComponent data={this.serviceHoldList} label="Hold" itemKey="value" id={rowId + "_service_hold"} disabled={rowData.is_wholesale || disabled} onItemClick={this.onServiceHoldChange} value={rowData.metric_name} />
                </div>
                <div className="text-center">
                    {
                        !buttonDisabled && (!rowData.service_hold_metric_name || rowData.service_hold_metric_name === "NoHolds")
                            ? <div className="d-inline dropdown">
                                <button className={CssName + " dgdg-button-width dropdown-toggle"} type="button" id="serviceOutBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Service Out
                                </button>
                                <div className="dropdown-menu" aria-labelledby="serviceOutBtn">
                                    <button className={CssName + " dgdg-button-width dropdown-item"} onClick={(event) => this.onServiceOutChange(rowData, "Body")}>
                                        Service Out to Body
                                    </button>
                                    <button className={CssName + " dgdg-button-width dropdown-item"} onClick={(event) => this.onServiceOutChange(rowData, "Detail")}>
                                        Service Out to Detail
                                    </button>
                                </div>
                            </div>
                            : null
                    }
                </div>
                <div className="dgdg-used-reconditioning-label">
                    {rowData.service_out_due_age + " days since Entry"}
                </div>
            </Fragment>;
        }
    }

    onSmogHoldChange(id, value) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
        try {
            rowData.service_out_date_time_showSpinner = true;
            rowData.is_smog_hold = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = JSON.stringify({
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "Smog",
                inDateTime: value ? moment() : null,
                outDateTime: value ? null : moment()
            });

            let url = "";
            if (value)
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVinHoldChange(id, value) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
        try {
            rowData.is_vin_hold = value;
            rowData.service_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = JSON.stringify({
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "VIN",
                inDateTime: value ? moment() : null,
                outDateTime: value ? null : moment()
            });

            let url = "";
            if (value)
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onWeightHoldChange(id, value) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];

        try {
            rowData.is_weight_hold = value;
            rowData.service_out_date_time_showSpinner = true;

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = JSON.stringify({
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "Weight",
                inDateTime: value ? moment() : null,
                outDateTime: value ? null : moment()
            });

            let url = "";
            if (value)
                url = "/UsedReconditioning/SaveInventoryReconTimingIn";
            else
                url = "/UsedReconditioning/SaveInventoryReconTimingOut";

            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onServiceHoldChange(id, selectedItem) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
        try {
            rowData.service_out_date_time_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData;
            if (rowData.service_hold_metric_name && rowData.service_hold_metric_name !== "NoHolds") {
                inputData = JSON.stringify({
                    storeNumber: rowData.store_number,
                    stockNumber: rowData.stock_number,
                    vin: rowData.vin,
                    metricName: rowData.service_hold_metric_name,
                    outDateTime: moment()
                });
                fetch("/UsedReconditioning/SaveInventoryReconTimingOut", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: inputData
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }
            if (selectedItem.key !== "NoHolds") {
                inputData = JSON.stringify({
                    storeNumber: rowData.store_number,
                    stockNumber: rowData.stock_number,
                    vin: rowData.vin,
                    metricName: selectedItem.key,
                    inDateTime: moment()
                });
                fetch("/UsedReconditioning/SaveInventoryReconTimingIn", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: inputData
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.devError(jsonParseError, null, this.props.applicationInsights);
                                });
                        }
                    });
            }

            rowData.service_hold_metric_name = selectedItem.key;
            rowData.metric_name = selectedItem.value;
            inputData = JSON.stringify({
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                serviceHoldMetricName: selectedItem.key
            });
            fetch("/UsedReconditioning/SaveInventoryReconServiceHoldMetricName", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    this.configureData(tableData, this.state.selectedStore);
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.service_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onServiceOutChange(rowData, serviceOutBtn) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                metricName: "Service",
                inDateTime: moment(rowData.entry_date),
            }
            this.saveService(inputData, rowData, "In")
                .then(() => {
                    let inputData = {
                        storeNumber: rowData.store_number,
                        stockNumber: rowData.stock_number,
                        vin: rowData.vin,
                        year: rowData.year,
                        make: rowData.make,
                        model: rowData.model,
                        metricName: "Service",
                        outDateTime: moment()
                    }
                    this.saveService(inputData, rowData, "Out")
                        .then(() => {
                            let inputData = {
                                storeNumber: rowData.store_number,
                                stockNumber: rowData.stock_number,
                                vin: rowData.vin,
                                metricName: serviceOutBtn,
                                inDateTime: moment()
                            };
                            if (serviceOutBtn === "Body") {
                                this.saveBody(inputData, rowData, "In");
                            }
                            else {
                                this.saveDetail(inputData, rowData, "In");
                            }
                        });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onServiceOutDateChange(id, value) {
        try {
            let stockNumber = id.split('_')[1];
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === stockNumber)[0];
            rowData.service_out_date_time = moment(value);
            this.setState({
                serviceOutData: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkServiceOut(event) {
        try {
            let inputData = {
                storeNumber: this.state.serviceOutData.store_number,
                stockNumber: this.state.serviceOutData.stock_number,
                vin: this.state.serviceOutData.vin,
                year: this.state.serviceOutData.year,
                make: this.state.serviceOutData.make,
                model: this.state.serviceOutData.model,
                metricName: "Service",
                outDateTime: this.state.serviceOutData.service_out_date_time
            }
            this.saveService(inputData, this.state.serviceOutData, "Out");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onBodyOutCarousalCallback(rowData) {
        if (!rowData.service_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (rowData.body_out_date_time || rowData.detail_in_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (rowData.detail_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onBodyOutColumnCallback(rowData) {
        let rowId = "bodyOutDate_" + rowData.stock_number;
        if (rowData.body_out_date_time)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.body_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + rowData.body_out_age + " days since Service)"}
                    <br />
                    {"By " + rowData.body_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id={rowId} disabled={rowData.is_wholesale} value={rowData.body_out_date_time} hideTextbox onDateChange={this.onBodyOutChange} minDate={this.startDate} maxDate={this.endDate} cssName="dgdg-used-reconditioning-calendar-icon" buttonCssName="dgdg-used-reconditioning-calendar-button" isHidden />
                        : null
                }
                {
                    rowData.can_undo_body_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Body Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let CssName = "btn btn-success";
            let disabled = (rowData.edit_service === 0 || !(rowData.service_out_date_time && rowData.body_in_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold) || rowData.body_out_date_time) || rowData.is_wholesale;
            if (rowData.body_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="text-center">
                    {
                        !disabled
                            ? this.state.selectedStore && this.state.selectedStore.store_number !== 11
                                ? <Fragment>
                                    <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onBodyOutClick(event, rowData)}>Body Out</button>
                                    <br />
                                    <span className="dgdg-used-reconditioning-label">
                                        {rowData.body_out_due_age + " days since Service"}
                                    </span>
                                </Fragment>
                                : this.state.selectedStore && this.state.selectedStore.store_number === 11
                                    ? <Fragment>
                                        <br />
                                        <span className="dgdg-used-reconditioning-label">
                                            {rowData.body_out_due_age + " days since Service"}
                                        </span>
                                        <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onBodyOutClick(event, rowData)}>Body Out</button>
                                    </Fragment>
                                    : null
                            : <label>N/A</label>
                    }
                </div>
            </Fragment>;
        }
    }

    onBodyOutClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                metricName: "Body",
                outDateTime: moment()
            }
            this.saveBody(inputData, rowData, "Out");

            inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "Detail",
                inDateTime: moment()
            }
            this.saveDetail(inputData, rowData, "In");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onBodyOutChange(id, value) {
        try {
            let stockNumber = id.split('_')[1];
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === stockNumber)[0];
            rowData.body_out_date_time = moment(value);
            this.setState({
                bodyOutData: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkBodyOut(event) {
        try {
            let inputData = {
                storeNumber: this.state.bodyOutData.store_number,
                stockNumber: this.state.bodyOutData.stock_number,
                vin: this.state.bodyOutData.vin,
                year: this.state.bodyOutData.year,
                make: this.state.bodyOutData.make,
                model: this.state.bodyOutData.model,
                metricName: "Body",
                outDateTime: this.state.bodyOutData.body_out_date_time
            }
            this.saveBody(inputData, this.state.bodyOutData, "Out");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDetailOutCarousalCallback(rowData) {
        if (!rowData.service_out_date_time || (!rowData.body_out_date_time && !rowData.detail_in_date_time)) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (rowData.detail_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (rowData.recon_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onDetailOutColumnCallback(rowData) {
        let rowId = "detailOutDate_" + rowData.stock_number;
        if (rowData.detail_out_date_time)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.detail_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + rowData.detail_out_age + " days since " + (rowData.body_out_date_time ? "Body)" : "Service)")}
                    <br />
                    {"By " + rowData.detail_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id={rowId} disabled={rowData.is_wholesale} value={rowData.detail_out_date_time} hideTextbox onDateChange={this.onDetailOutChange} minDate={this.startDate} maxDate={this.endDate} cssName="dgdg-used-reconditioning-calendar-icon" buttonCssName="dgdg-used-reconditioning-calendar-button" isHidden />
                        : null
                }
                {
                    rowData.can_undo_detail_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Detail Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let CssName = "btn btn-success";
            let disabled = (rowData.edit_detail === 0 || !(rowData.service_out_date_time && rowData.detail_in_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold) || rowData.detail_out_date_time) || rowData.is_wholesale;
            if (rowData.detail_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="text-center">
                    {
                        !disabled && rowData.detail_in_date_time
                            ? this.state.selectedStore && this.state.selectedStore.store_number !== 11
                                ? <Fragment>
                                    <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onDetailOutClick(event, rowData)}>Detail Out</button>
                                    <br />
                                    <span className="dgdg-used-reconditioning-label">
                                        {rowData.detail_out_due_age + " days since " + (rowData.body_out_date_time ? "Body" : "Service")}
                                    </span>
                                </Fragment>
                                : this.state.selectedStore && this.state.selectedStore.store_number === 11
                                    ? <Fragment>
                                        <DGDGFormCheckboxV2Component label="Paint:" id={rowId + "_PaintHold"} value={rowData.is_paint_hold} onChange={this.onPaintHoldChange} controlCssName="dgdg-used-reconditioning-toggle-yellow" />
                                        <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onDetailOutClick(event, rowData)} disabled={rowData.is_paint_hold}>Detail Out</button>
                                        <br />
                                        <span className="dgdg-used-reconditioning-label">
                                            {rowData.detail_out_due_age + " days since " + (rowData.body_out_date_time ? "Body" : "Service")}
                                        </span>
                                    </Fragment>
                                    : null
                            : <label>N/A</label>
                    }
                </div>
            </Fragment>;
        }
    }

    onDetailOutClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                metricName: "Detail",
                outDateTime: moment()
            }
            this.saveDetail(inputData, rowData, "Out");

            inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "Recon",
                inDateTime: moment()
            }
            this.saveRecon(inputData, rowData, "In");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDetailOutChange(id, value) {
        try {
            let stockNumber = id.split('_')[1];
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === stockNumber)[0];
            rowData.detail_out_date_time = moment(value);
            this.setState({
                detailOutData: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkDetailOut(event) {
        try {
            let inputData = {
                storeNumber: this.state.detailOutData.store_number,
                stockNumber: this.state.detailOutData.stock_number,
                vin: this.state.detailOutData.vin,
                year: this.state.detailOutData.year,
                make: this.state.detailOutData.make,
                model: this.state.detailOutData.model,
                metricName: "Detail",
                outDateTime: this.state.detailOutData.detail_out_date_time
            }
            this.saveDetail(inputData, this.state.detailOutData, "Out");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReconOutCarousalCallback(rowData) {
        if (!rowData.detail_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        let statusOut = "";
        if (rowData.recon_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
            if (rowData.photo_out_date_time) {
                statusOut = "completed";
            } else {
                statusOut = "pending";
            }
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} statusOut={statusOut} />;
    }

    onReconOutColumnCallback(rowData) {
        let rowId = "reconOutDate_" + rowData.stock_number;
        if (rowData.recon_out_date_time)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.recon_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + rowData.recon_out_age + " days since Detail)"}
                    <br />
                    {"By " + rowData.recon_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id={rowId} disabled={rowData.is_wholesale} value={rowData.recon_out_date_time} hideTextbox onDateChange={this.onReconOutChange} minDate={this.startDate} maxDate={this.endDate} cssName="dgdg-used-reconditioning-calendar-icon" buttonCssName="dgdg-used-reconditioning-calendar-button" isHidden />
                        : null
                }
                {
                    rowData.can_undo_recon_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Recon Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let disabled = (rowData.edit_recon === 0 || !(rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold) || !rowData.detail_out_date_time || rowData.recon_out_date_time) || rowData.is_wholesale;
            let CssName = "btn btn-success";
            if (rowData.recon_out_due_age >= 2)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="text-center">
                    {
                        !disabled
                            ? <Fragment>
                                {
                                    this.state.selectedStore && this.state.selectedStore.store_number !== 11
                                        ? <Fragment>
                                            <div className="form-inline">
                                                <DGDGFormCheckboxComponent label="Interior Only" id={rowId + "_ReconInteriorOnly"} value={rowData.recon_interior_only} onChange={this.onReconInteriorOnlyChange} />
                                            </div>
                                            <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onReconOutClick(event, rowData)}>Recon Out</button>
                                            <br />
                                            <span className="dgdg-used-reconditioning-label">
                                                {rowData.recon_out_due_age + " days since Detail"}
                                            </span>
                                        </Fragment>
                                        : this.state.selectedStore && this.state.selectedStore.store_number === 11
                                            ? <Fragment>
                                                <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onReconOutClick(event, rowData)}>Recon Out</button>
                                                <br />
                                                <span className="dgdg-used-reconditioning-label">
                                                    {rowData.recon_out_due_age + " days since Detail"}
                                                </span>
                                            </Fragment>
                                            : null
                                }
                            </Fragment>
                            : <label>N/A</label>
                    }
                </div>
            </Fragment>;
        }
    }

    onReconInteriorOnlyChange(id, value) {
        try {
            let stockNumber = id.split('_')[1];
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === stockNumber)[0];
            rowData.recon_interior_only = value;

            let inputData = {
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                isReconInteriorOnly: value
            };
            fetch("/UsedReconditioning/SaveReconInteriorOnly", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.recon_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.recon_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPaintHoldChange(id, value) {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
        try {
            rowData.detail_out_date_time_showSpinner = true;
            rowData.is_paint_hold = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                detailPaint: value,
                dateTime: moment()
            };
            fetch("/UsedReconditioning/SaveDetailPaint", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.detail_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.detail_out_date_time_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReconOutClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                metricName: "Recon",
                outDateTime: moment()
            }
            this.saveRecon(inputData, rowData, "Out");

            inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                metricName: "Photo",
                inDateTime: moment()
            }
            this.savePhoto(inputData, rowData, "In");

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onReconOutChange(id, value) {
        try {
            let stockNumber = id.split('_')[1];
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === stockNumber)[0];
            rowData.recon_out_date_time = moment(value);
            this.setState({
                reconOutData: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkReconOut(event) {
        try {
            let inputData = {
                storeNumber: this.state.reconOutData.store_number,
                stockNumber: this.state.reconOutData.stock_number,
                vin: this.state.reconOutData.vin,
                year: this.state.reconOutData.year,
                make: this.state.reconOutData.make,
                model: this.state.reconOutData.model,
                metricName: "Recon",
                outDateTime: this.state.reconOutData.recon_out_date_time
            }
            this.saveRecon(inputData, this.state.reconOutData, "Out");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPhotoOutCarousalCallback(rowData) {
        if (!rowData.recon_out_date_time) {
            return null;
        }

        let text = "Pending";
        let status = "pending";
        let statusIn = "pending";
        if (rowData.photo_out_date_time) {
            text = "Completed";
            status = "completed";
            statusIn = "completed";
        }

        return <DGDGCarouselComponent text={text} status={status} statusIn={statusIn} />;
    }

    onPhotoOutColumnCallback(rowData) {
        let rowId = "photoOutDate_" + rowData.stock_number;
        if (rowData.photo_out_date_time)
            return <Fragment>
                {CommonService.formatDate(moment(rowData.photo_out_date_time))}
                <span className="dgdg-used-reconditioning-label">
                    {" (" + rowData.photo_out_age + " days since Recon)"}
                    <br />
                    {"By " + rowData.photo_out_by.replace("@dgdgeng.com", "").replace("@dgdg.com", "").replace(".", " ")}
                </span>
                {
                    !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id={rowId} disabled={rowData.is_wholesale} value={rowData.photo_out_date_time} hideTextbox onDateChange={this.onPhotoOutChange} minDate={this.startDate} maxDate={this.endDate} cssName="dgdg-used-reconditioning-calendar-icon" buttonCssName="dgdg-used-reconditioning-calendar-button" isHidden />
                        : null
                }
                {
                    rowData.can_undo_photo_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold
                        ? <button type="button" className="btn btn-link dgdg-used-reconditioning-undo-button" onClick={(event) => { if (rowData.is_wholesale) return false; this.onUndoInventoryClick(event, rowData, "Photo Out"); }}>
                            <span className="fas fa-undo" />
                        </button>
                        : null
                }
            </Fragment>;
        else {
            let disabled = (rowData.edit_photo === 0 || !(rowData.service_out_date_time && !rowData.is_smog_hold && !rowData.is_vin_hold && !rowData.is_weight_hold) || !rowData.detail_out_date_time || !rowData.recon_out_date_time || rowData.photo_out_date_time) || rowData.is_wholesale;
            let CssName = "btn btn-success";
            if (rowData.photo_out_due_age >= 1)
                CssName = "btn btn-danger";
            else if (disabled)
                CssName = "btn btn-primary";

            return <Fragment>
                <div className="text-center">
                    {
                        !disabled
                            ? <Fragment>
                                <button type="button" className={CssName + " dgdg-button-width"} onClick={(event) => this.onPhotoOutClick(event, rowData)}>Photo Out</button>
                                <br />
                                <span className="dgdg-used-reconditioning-label">
                                    {rowData.photo_out_due_age + " days since Recon"}
                                </span>
                            </Fragment>
                            : <label>N/A</label>
                    }
                </div>
            </Fragment>;
        }
    }

    onPhotoOutClick(event, rowData) {
        try {
            let inputData = {
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                year: rowData.year,
                make: rowData.make,
                model: rowData.model,
                metricName: "Photo",
                outDateTime: moment()
            }
            this.savePhoto(inputData, rowData, "Out");

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPhotoOutChange(id, value) {
        try {
            let stockNumber = id.split('_')[1];
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === stockNumber)[0];
            rowData.photo_out_date_time = moment(value);
            this.setState({
                photoOutData: rowData
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkPhotoOut(event) {
        try {
            let inputData = {
                storeNumber: this.state.photoOutData.store_number,
                stockNumber: this.state.photoOutData.stock_number,
                vin: this.state.photoOutData.vin,
                year: this.state.photoOutData.year,
                make: this.state.photoOutData.make,
                model: this.state.photoOutData.model,
                metricName: "Photo",
                outDateTime: this.state.photoOutData.photo_out_date_time
            }
            this.savePhoto(inputData, this.state.photoOutData, "Out");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUndoInventoryClick(event, rowData, actionName) {
        try {
            this.setState({
                undoDetail: rowData,
                actionName: actionName
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onUndoOkClick(event) {
        let inputData = {
            storeNumber: this.state.undoDetail.store_number,
            stockNumber: this.state.undoDetail.stock_number,
            vin: this.state.undoDetail.vin,
            year: this.state.undoDetail.year,
            make: this.state.undoDetail.make,
            model: this.state.undoDetail.model,
            outDateTime: null,
            inDateTime: null
        }
        switch (this.state.actionName) {
            case "Service Out":
                if (this.state.undoDetail.body_in_date_time == null) {
                    inputData.metricName = "Detail";
                    this.saveDetail(inputData, this.state.undoDetail, "In")
                        .then(() => {
                            inputData.metricName = "Service";
                            this.saveService(inputData, this.state.undoDetail, "Out");
                            this.onCancelUndoClick();
                        });
                }
                else {
                    inputData.metricName = "Body";
                    this.saveBody(inputData, this.state.undoDetail, "In")
                        .then(() => {
                            inputData.metricName = "Service";
                            this.saveService(inputData, this.state.undoDetail, "Out");
                            this.onCancelUndoClick();
                        });
                }
                break;
            case "Body Out":
                inputData.metricName = "Detail";
                this.saveDetail(inputData, this.state.undoDetail, "In")
                    .then(() => {
                        inputData.metricName = "Body";
                        this.saveBody(inputData, this.state.undoDetail, "Out");
                        this.onCancelUndoClick();
                    });

                break;
            case "Detail Out":
                inputData.metricName = "Recon";
                this.saveRecon(inputData, this.state.undoDetail, "In")
                    .then(() => {
                        inputData.metricName = "Detail";
                        this.saveDetail(inputData, this.state.undoDetail, "Out");
                        this.onCancelUndoClick();
                    });
                break;
            case "Recon Out":
                inputData.metricName = "Photo";
                this.savePhoto(inputData, this.state.undoDetail, "In")
                    .then(() => {
                        inputData.metricName = "Recon";
                        this.saveRecon(inputData, this.state.undoDetail, "Out");
                        this.onCancelUndoClick();
                    });
                break;
            case "Photo Out":
                inputData.metricName = "Photo";
                this.savePhoto(inputData, this.state.undoDetail, "Out");
                this.onCancelUndoClick();
                break;
            default:
                this.onCancelUndoClick();
                break;
        }
    }

    onCancelUndoClick(event) {
        try {
            this.setState({ undoDetail: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddCommentColumnCallback(rowData) {
        let comments = this.getComments(rowData.comments);
        let disableAddComments = !rowData.edit_comment;
        return <Fragment>
            <button key="btnViewComments" type="button" className="btn btn-link" onClick={(event) => this.onToggleViewComments(event, rowData)}>{comments.length / 2} Comments
                {
                    rowData.showViewComments
                        ? <span className="far fa-sort-up dgdg-icon" />
                        : <span className="far fa-sort-down dgdg-icon" />
                }
            </button>
            <button key="btnAddComment" type="button" className="btn btn-link dgdg-icon" disabled={disableAddComments} onClick={(event) => this.onToggleAddComments(event, rowData)}>Add Comments
                {
                    rowData.showAddComment
                        ? <span className="fas fa-times text-danger dgdg-icon" />
                        : <span className="fas fa-plus dgdg-icon" />
                }
            </button>
            <br />
            {
                rowData.showViewComments
                    ? comments
                    : null
            }
            {
                rowData.showAddComment
                    ? <div className="form-inline">
                        <DGDGFormTextAreaComponent id={"comments_" + rowData.stock_number} value={rowData["comments_" + rowData.stock_number]} rows="3" onChange={this.onCommentTextChange} colSpan="col-11" controlSpan="col-12" />
                        <button type="button" className="btn btn-link dgdg-font-size-18" disabled={disableAddComments} onClick={(event) => this.onSaveCommentClick(event, rowData)}>
                            <span className="fas fa-save" />
                        </button>
                        <DGDGSpinnerComponent showSpinner={rowData.comments_showSpinner} />
                    </div>
                    : null
            }
        </Fragment>;
    }

    onToggleViewComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showViewComments = rowData.showViewComments ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
        });
    }

    onToggleAddComments(event, rowData) {
        let tableData = this.state.tableData;
        rowData.showAddComment = rowData.showAddComment ? false : true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            if (rowData.showAddComment) {
                let id = "#comments_" + rowData.stock_number;
                $(id).focus();
            }
        });
    }

    onCommentTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === id.split('_')[1])[0];
            rowData["comments_" + rowData.stock_number] = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveCommentClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comments_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = JSON.stringify({
                storeNumber: rowData.store_number,
                stockNumber: rowData.stock_number,
                vin: rowData.vin,
                comments: rowData["comments_" + rowData?.stock_number].replace(/\n|\r/g, " ")
            });
            fetch("/UsedReconditioning/SaveComments", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    rowData.comments = moment().format("MM/DD/YYYY hh:mm A") + ", " + this.props.remoteUpn + ": " + rowData["comments_" + rowData.stock_number] + (rowData.comments ? '\r' + rowData.comments : "");
                    rowData["comments_" + rowData.stock_number] = "";
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.comments_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelDateChange(event) {
        try {
            this.setState({
                serviceOutData: null,
                bodyOutData: null,
                detailOutData: null,
                reconOutData: null,
                photoOutData: null
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    roNumberColumnCallback(rowData) {
        return <Fragment>
            <a href={"/RO/" + rowData.store_number + "/" + rowData.recon_ro_number} target="_self">{rowData.recon_ro_number}</a>
            <br />
            <a href={"/RO/All/All/All/" + rowData.vin} className="text-nowrap" target="_self">List VIN ROs</a>
        </Fragment>
    }

    onPublishSelectChange(id, isChecked, rowData) {
        try {
            rowData.ok_publish = isChecked;
            this.setState({
                publishRow: rowData,
                showPublishConfirmation: true,
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onOkPublishChange() {
        let tableData = this.state.tableData;
        let rowData = tableData.filter((row) => row.stock_number === this.state.publishRow.stock_number)[0];
        try {
            rowData.ok_publish = this.state.publishRow.ok_publish;
            rowData.ok_publish_showSpinner = true;
            rowData.inventory_state = this.state.publishRow.ok_publish === true ? "InPrep" : "Live";

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({
                    paintTable: false,
                    showPublishConfirmation: false
                });
            });

            let inputData = JSON.stringify({
                stockNumber: this.state.publishRow.stock_number,
                homenetState: rowData.inventory_state
            });
            fetch("/UsedReconditioning/SaveHomenetState", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.configureData(tableData, this.state.selectedStore);
                    rowData.ok_publish_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.ok_publish_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                })
                .finally(() => {
                    this.setState({ isLoadingData: false });
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelPublishClick() {
        if (this.state.showPublishConfirmation) {
            let tableData = this.state.tableData;
            let rowData = tableData.filter((row) => row.stock_number === this.state.publishRow.stock_number)[0];
            rowData.ok_publish = this.state.publishRow.ok_publish ? false : true;
            try {
                this.setState({
                    tableData: tableData,
                    showPublishConfirmation: false
                });
            }
            catch (error) {
                console.devError(error, null, this.props.applicationInsights);
            }
        }
    }

    onSortByChange(sortBy) {
        this.setState({
            tableData: CommonService.sortJsonData(this.state.tableData, sortBy, "Desc"),
            paintTable: true,
        });
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <UsedReconditioningInventoryQueryComponent
                applicationInsights={this.props.applicationInsights}
                stores={this.props.stores} isLoadingData={this.state.isLoadingData}
                onGetReportClick={this.onGetReportClick} onSortByChange={this.onSortByChange}
            />
            <div className="dgdg-desktop dgdg-site-scrollable-content">
                <UsedReconditioningInventoryDesktopComponent
                    applicationInsights={this.props.applicationInsights}

                    activeStores={this.props.activeStores}
                    selectedStore={this.state.selectedStore}

                    tableData={this.state.tableData}
                    tableTitle={this.state.tableTitle}
                    paintTable={this.state.paintTable}
                    isLoadingData={this.state.isLoadingData}
                    legendText={this.state.legendText}

                    serviceActiveCount={this.state.serviceActiveCount}
                    serviceHoldList={this.serviceHoldList}
                    serviceOutData={this.state.serviceOutData}

                    bodyActiveCount={this.state.bodyActiveCount}
                    bodyOutData={this.state.bodyOutData}

                    detailActiveCount={this.state.detailActiveCount}
                    detailOutData={this.state.detailOutData}

                    reconOutData={this.state.reconOutData}
                    reconActiveCount={this.state.reconActiveCount}

                    photoActiveCount={this.state.photoActiveCount}
                    photoOutData={this.state.photoOutData}

                    actionName={this.state.actionName}
                    paintActiveCount={this.state.paintActiveCount}

                    wholesaleRow={this.state.wholesaleRow}
                    showWholesaleConfirmation={this.state.showWholesaleConfirmation}
                    publishRow={this.state.publishRow}
                    showPublishConfirmation={this.state.showPublishConfirmation}

                    cpoRow={this.state.cpoRow}
                    showCPOConfirmation={this.state.showCPOConfirmation}

                    undoDetail={this.state.undoDetail}

                    onGetReportClick={this.onGetReportClick}
                    onCopyTableCallBack={this.onCopyTableCallBack}

                    onServiceOutCarousalCallback={this.onServiceOutCarousalCallback}
                    onServiceOutColumnCallback={this.onServiceOutColumnCallback}
                    onServiceHoldChange={this.onServiceHoldChange}
                    onOkServiceOut={this.onOkServiceOut}
                    onServiceOutChange={this.onServiceOutChange}
                    onServiceOutDateChange={this.onServiceOutDateChange}

                    onBodyOutCarousalCallback={this.onBodyOutCarousalCallback}
                    onBodyOutColumnCallback={this.onBodyOutColumnCallback}
                    onOkBodyOut={this.onOkBodyOut}
                    onBodyOutClick={this.onBodyOutClick}
                    onBodyOutChange={this.onBodyOutChange}

                    onDetailOutCarousalCallback={this.onDetailOutCarousalCallback}
                    onDetailOutColumnCallback={this.onDetailOutColumnCallback}
                    onOkDetailOut={this.onOkDetailOut}
                    onDetailOutClick={this.onDetailOutClick}
                    onDetailOutChange={this.onDetailOutChange}

                    onReconOutCarousalCallback={this.onReconOutCarousalCallback}
                    onReconOutColumnCallback={this.onReconOutColumnCallback}
                    onOkReconOut={this.onOkReconOut}
                    onReconOutChange={this.onReconOutChange}
                    onReconInteriorOnlyChange={this.onReconInteriorOnlyChange}

                    onPhotoOutCarousalCallback={this.onPhotoOutCarousalCallback}
                    onPhotoOutColumnCallback={this.onPhotoOutColumnCallback}
                    onOkPhotoOut={this.onOkPhotoOut}
                    onPhotoOutClick={this.onPhotoOutClick}
                    onPhotoOutChange={this.onPhotoOutChange}

                    onCancelDateChange={this.onCancelDateChange}

                    onPublishSelectChange={this.onPublishSelectChange}
                    onOkPublishChange={this.onOkPublishChange}
                    onCancelPublishClick={this.onCancelPublishClick}

                    onWholesaleSelectChange={this.onWholesaleSelectChange}
                    onOkWholesaleChange={this.onOkWholesaleChange}
                    onCancelWholesaleClick={this.onCancelWholesaleClick}

                    onCPOSelectChange={this.onCPOSelectChange}
                    onOkCPOChange={this.onOkCPOChange}
                    onCancelCPOClick={this.onCancelCPOClick}

                    onPartsHoldChange={this.onPartsHoldChange}
                    onSmogHoldChange={this.onSmogHoldChange}
                    onVinHoldChange={this.onVinHoldChange}
                    onWeightHoldChange={this.onWeightHoldChange}
                    onReconOutClick={this.onReconOutClick}

                    onPaintHoldChange={this.onPaintHoldChange}

                    onUndoInventoryClick={this.onUndoInventoryClick}
                    onUndoOkClick={this.onUndoOkClick}
                    onCancelUndoClick={this.onCancelUndoClick}

                    onAddCommentColumnCallback={this.onAddCommentColumnCallback}
                    onToggleViewComments={this.onToggleViewComments}
                    onToggleAddComments={this.onToggleAddComments}
                    onCommentTextChange={this.onCommentTextChange}
                    onSaveCommentClick={this.onSaveCommentClick}

                    onEstCompletionDateColumnCallback={this.onEstCompletionDateColumnCallback}
                    onEstCompletionDateChange={this.onEstCompletionDateChange}
                    onSaveEstCompletionDateClick={this.onSaveEstCompletionDateClick}

                    roNumberColumnCallback={this.roNumberColumnCallback}
                />
            </div>
            <div className="dgdg-mobile dgdg-site-scrollable-content">
                <UsedReconditioningInventoryMobileComponent
                    applicationInsights={this.props.applicationInsights}

                    activeStores={this.props.activeStores}
                    selectedStore={this.state.selectedStore}

                    tableData={this.state.tableData}
                    tableTitle={this.state.tableTitle}
                    paintTable={this.state.paintTable}
                    isLoadingData={this.state.isLoadingData}
                    legendText={this.state.legendText}

                    serviceActiveCount={this.state.serviceActiveCount}
                    serviceHoldList={this.serviceHoldList}
                    serviceOutData={this.state.serviceOutData}

                    bodyActiveCount={this.state.bodyActiveCount}
                    bodyOutData={this.state.bodyOutData}

                    detailActiveCount={this.state.detailActiveCount}
                    detailOutData={this.state.detailOutData}

                    reconOutData={this.state.reconOutData}
                    reconActiveCount={this.state.reconActiveCount}

                    photoActiveCount={this.state.photoActiveCount}
                    photoOutData={this.state.photoOutData}

                    actionName={this.state.actionName}
                    paintActiveCount={this.state.paintActiveCount}

                    wholesaleRow={this.state.wholesaleRow}
                    showWholesaleConfirmation={this.state.showWholesaleConfirmation}
                    publishRow={this.state.publishRow}
                    showPublishConfirmation={this.state.showPublishConfirmation}

                    cpoRow={this.state.cpoRow}
                    showCPOConfirmation={this.state.showCPOConfirmation}

                    undoDetail={this.state.undoDetail}

                    onGetReportClick={this.onGetReportClick}
                    onCopyTableCallBack={this.onCopyTableCallBack}

                    onServiceOutCarousalCallback={this.onServiceOutCarousalCallback}
                    onServiceOutColumnCallback={this.onServiceOutColumnCallback}
                    onServiceHoldChange={this.onServiceHoldChange}
                    onOkServiceOut={this.onOkServiceOut}
                    onServiceOutChange={this.onServiceOutChange}
                    onServiceOutDateChange={this.onServiceOutDateChange}

                    onBodyOutCarousalCallback={this.onBodyOutCarousalCallback}
                    onBodyOutColumnCallback={this.onBodyOutColumnCallback}
                    onOkBodyOut={this.onOkBodyOut}
                    onBodyOutClick={this.onBodyOutClick}
                    onBodyOutChange={this.onBodyOutChange}

                    onDetailOutCarousalCallback={this.onDetailOutCarousalCallback}
                    onDetailOutColumnCallback={this.onDetailOutColumnCallback}
                    onOkDetailOut={this.onOkDetailOut}
                    onDetailOutClick={this.onDetailOutClick}
                    onDetailOutChange={this.onDetailOutChange}

                    onReconOutCarousalCallback={this.onReconOutCarousalCallback}
                    onReconOutColumnCallback={this.onReconOutColumnCallback}
                    onOkReconOut={this.onOkReconOut}
                    onReconOutChange={this.onReconOutChange}
                    onReconInteriorOnlyChange={this.onReconInteriorOnlyChange}

                    onPhotoOutCarousalCallback={this.onPhotoOutCarousalCallback}
                    onPhotoOutColumnCallback={this.onPhotoOutColumnCallback}
                    onOkPhotoOut={this.onOkPhotoOut}
                    onPhotoOutClick={this.onPhotoOutClick}
                    onPhotoOutChange={this.onPhotoOutChange}

                    onCancelDateChange={this.onCancelDateChange}

                    onPublishSelectChange={this.onPublishSelectChange}
                    onOkPublishChange={this.onOkPublishChange}
                    onCancelPublishClick={this.onCancelPublishClick}

                    onWholesaleSelectChange={this.onWholesaleSelectChange}
                    onOkWholesaleChange={this.onOkWholesaleChange}
                    onCancelWholesaleClick={this.onCancelWholesaleClick}

                    onCPOSelectChange={this.onCPOSelectChange}
                    onOkCPOChange={this.onOkCPOChange}
                    onCancelCPOClick={this.onCancelCPOClick}

                    onPartsHoldChange={this.onPartsHoldChange}
                    onSmogHoldChange={this.onSmogHoldChange}
                    onVinHoldChange={this.onVinHoldChange}
                    onWeightHoldChange={this.onWeightHoldChange}
                    onReconOutClick={this.onReconOutClick}

                    onPaintHoldChange={this.onPaintHoldChange}

                    onUndoInventoryClick={this.onUndoInventoryClick}
                    onUndoOkClick={this.onUndoOkClick}
                    onCancelUndoClick={this.onCancelUndoClick}

                    onAddCommentColumnCallback={this.onAddCommentColumnCallback}
                    onToggleViewComments={this.onToggleViewComments}
                    onToggleAddComments={this.onToggleAddComments}
                    onCommentTextChange={this.onCommentTextChange}
                    onSaveCommentClick={this.onSaveCommentClick}

                    onEstCompletionDateColumnCallback={this.onEstCompletionDateColumnCallback}
                    onEstCompletionDateChange={this.onEstCompletionDateChange}
                    onSaveEstCompletionDateClick={this.onSaveEstCompletionDateClick}

                    roNumberColumnCallback={this.roNumberColumnCallback}
                />
            </div>
        </div>;
    }
}