import React, { Component } from "react";

import { SiteRootComponent } from "./components/site/SiteRootComponent";

import "./custom.css";
import "./components/js_modules/fontawesome-pro-6.4.2-web/css/all.min.css";


export default class App extends Component {
    static displayName = App.name;

    render() {
        return <SiteRootComponent {...this.props} />;
    }
}