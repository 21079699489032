import React from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";
import { DGDGQueryComponent } from "../common/dgdgquery/DGDGQueryComponent";
export class ActiveDirectoryUserQueryComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            console.devLog("ActiveDirectoryUserQueryComponent - getDerivedStateFromProps");
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            filterValue: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
            this.props.onLookupClick(value);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onLookupClick(id, value) {
        try {
            this.setState({
                filterValue: value,
                showSpinner: true
            });
            this.props.onLookupClick(value);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSearchClick(event) {
        this.props.onSearchClick();
    }

    render() {
        return <DGDGQueryComponent cssName="dgdg-card-gutter">
            <DGDGFormInputComponent id="filterValue" label="Active Directory User Filter" value={this.state.filterValue} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />
            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onSearchClick(event)}>
                Search<i className="far fa-search dgdg-icon" />
            </button>
            <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
        </DGDGQueryComponent>
    }
}
