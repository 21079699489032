import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class ManageReportsRolesManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRole: null,
            selectedPermission: null,
            deleteRole: null,
            tableData: [],
            unAssignedRolesList: [],
            dialogTitle: "",
            permissionList: [
                { key: "Contributor", value: "Contributor" },
                { key: "Reader", value: "Reader" },
            ]
        };

        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onRoleColumnCallback = this.onRoleColumnCallback.bind(this);
        this.onPermissionColumnCallback = this.onPermissionColumnCallback.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
        this.onPermissionChange = this.onPermissionChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onShowConfirmationClick = this.onShowConfirmationClick.bind(this);
        this.onConfirmationOkClick = this.onConfirmationOkClick.bind(this);
        this.onConfirmationCancelClick = this.onConfirmationCancelClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.tableData !== null && this.props.tableData !== prevProps.tableData && this.props.unAssignedRolesList && this.props.reportName) {
                let dialogTitle = this.props.reportName + " Roles and Permissions";
                this.setState({
                    dialogTitle: dialogTitle,
                    unAssignedRolesList: this.props.unAssignedRolesList,
                    tableData: this.props.tableData,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Roles and Permissions";

    onActionColumnCallback(rowData) {
        return rowData.application_role_ext_name === undefined
            ? <Fragment>
                <button type="button" disabled={!this.state.selectedRole || !this.state.selectedPermission} className="btn btn-link" onClick={(event) => this.onSaveClick(event, rowData)}>
                    <span className="fas fa-save dgdg-font-blue dgdg-font-size-18" aria-hidden="true" />
                </button>
                <button type="button" className="btn btn-link" onClick={(event) => this.onConfirmationOkClick(event, rowData)}>
                    <span className="fas fa-window-close text-danger dgdg-font-size-18 dgdg-icon" aria-hidden="true" />
                </button>
            </Fragment>
            : <button type="button" className="btn btn-link" onClick={(event) => this.onShowConfirmationClick(event, rowData)}>
                <span className="fas fa-window-close text-danger dgdg-font-size-18 dgdg-icon" aria-hidden="true" />
            </button>;
    }

    onRoleColumnCallback(rowData) {
        return rowData.application_role_ext_name === undefined
            ? <DGDGFormDropdownComponent data={this.state.unAssignedRolesList} itemKey="application_role_ext_name" value={this.state.selectedRole ? this.state.selectedRole.application_role_ext_name : "Select Role"} onItemClick={this.onRoleChange} />
            : rowData.application_role_ext_name;
    }

    onPermissionColumnCallback(rowData) {
        return rowData.application_role_ext_name === undefined
            ? <DGDGFormDropdownComponent data={this.state.permissionList} itemKey="value" onItemClick={this.onPermissionChange} value={this.state.selectedPermission ? this.state.selectedPermission.value : "Select Permission"} colSpan="col-12" labelSpan="col-2" controlSpan="col-10" />
            : rowData.permission;
    }

    onRoleChange(id, selectedItem) {
        try {
            this.setState({
                selectedRole: selectedItem,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onPermissionChange(id, selectedItem) {
        try {
            this.setState({
                selectedPermission: selectedItem,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddClick(event) {
        try {
            let tableData = this.state.tableData;
            if (tableData.length === 0 || tableData[tableData.length - 1].application_role_ext_name !== undefined) {
                tableData.push({});
                this.setState({
                    tableData: tableData,
                    selectedRole: null,
                    selectedPermission: this.state.permissionList[0],
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onShowConfirmationClick(event, rowData) {
        try {
            this.setState({ deleteRole: rowData });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationOkClick(event, rowData) {
        try {
            if (rowData.application_role_ext_id === undefined) {
                let tableData = this.state.tableData;
                tableData.pop();
                this.setState({
                    tableData: tableData,
                    selectedRole: null,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
            else {
                this.props.onDeleteClick(event, {
                    name: this.props.reportName,
                    applicationRoleExtId: this.state.deleteRole.application_role_ext_id,
                    permission: this.state.selectedPermission.key
                });
            }

            this.onConfirmationCancelClick(event, rowData);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationCancelClick(event, rowData) {
        try {
            this.setState({ deleteRole: null });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.props.onSaveClick(event, {
                id: null,
                name: this.props.reportName,
                applicationRoleExtId: this.state.selectedRole.application_role_ext_id,
                permission: this.state.selectedPermission.key
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        this.setState({
            selectedRole: null,
            selectedPermission: null,
            deleteRole: null,
            tableData: [],
            unAssignedRolesList: [],
            dialogTitle: ""
        })

        this.props.onCancelClick(event);
    }

    render() {
        return <div>
            <DGDGModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.props.showDialog}
                cssName="dgdg-manage-report-role-dialog"
                title={this.state.dialogTitle}
                onCancelClick={this.onCancelClick}
            >
                <DGDGModalConfirmationComponent
                    applicationInsights={this.props.applicationInsights}
                    headerText={"Remove role: " + (this.state.deleteRole !== null ? this.state.deleteRole.application_role_ext_name : "")}
                    bodyText={"Are you sure?"}
                    showConfirmation={this.state.deleteRole !== null}
                    onOkClick={this.onConfirmationOkClick}
                    onCancelClick={this.onConfirmationCancelClick} />
                <div className="dgdg-report-body">
                    <div className="card">
                        <div className="card-header">
                            Roles & Permission
                            <button className="btn btn-primary dgdg-icon" onClick={(event) => this.onAddClick(event)}>
                                Add <span className="fas fa-plus dgdg-icon" />
                            </button>
                        </div>
                        <div className="card-block">
                            <DGDGTableV3Component applicationInsights={this.props.applicationInsights} tableData={this.state.tableData} paintTable={this.state.paintTable}>
                                <DGDGTableRowComponent>
                                    <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                    <DGDGTableColumnComponent headerText="Role" dataColumn="application_role_ext_name" cssName="dgdg-manage-report-role-column-width" dataColumnCallback={this.onRoleColumnCallback} />
                                    <DGDGTableColumnComponent headerText="Permission" dataColumn="permission" cssName="dgdg-manage-report-role-column-width" dataColumnCallback={this.onPermissionColumnCallback} />
                                    <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} />
                                </DGDGTableRowComponent>
                            </DGDGTableV3Component>
                        </div>
                    </div>
                </div>
            </DGDGModalDialogComponent >
        </div>
    }
}
