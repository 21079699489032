import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import $ from "jquery";
import "jquery-sparkline";
import "bootstrap";

import { CommonService } from "../../js_modules/dgdgjs/CommonService";
import { DGDGEditBoxComponent } from "../dgdgeditbox/DGDGEditBoxComponent";
import { DGDGEditDropdownComponent } from "../dgdgeditdropdown/DGDGEditDropdownComponent";
import { DGDGFormCheckboxComponent } from "../dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormCheckboxV2Component } from "../dgdgcheckbox/DGDGFormCheckboxV2Component";
import { DGDGFormDropdownComponent } from "../dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../dgdginput/DGDGFormInputComponent";
import { DGDGSpinnerComponent } from "../dgdgspinner/DGDGSpinnerComponent";
import { DGDGTableColumnCheckboxComponent } from "./DGDGTableColumnCheckboxComponent";
import { DGDGTableColumnCheckboxV2Component } from "./DGDGTableColumnCheckboxV2Component";
import { DGDGTableColumnDrCrComponent } from "./DGDGTableColumnDrCrComponent";
import { DGDGTableColumnDropdownComponent } from "./DGDGTableColumnDropdownComponent";
import { DGDGTableColumnInputComponent } from "./DGDGTableColumnInputComponent";
import { DGDGTableColumnSparklineComponent } from "./DGDGTableColumnSparklineComponent";
import { DGDGTableCustomRowComponent } from "./DGDGTableCustomRowComponent";
import { DGDGTableFilterComponent } from "./DGDGTableFilterComponent";
import { DGDGTableFooterComponent } from "./DGDGTableFooterComponent";

import { DGDGTableHeaderComponent } from "./DGDGTableHeaderComponent";
import { DGDGTableRowComponent } from "./DGDGTableRowComponent";

export class DGDGTableV3Component extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (props.tableData !== state.prevTableData) {
                let tableData = props.tableData;
                if (state.filterText !== "") {
                    tableData = CommonService.filterJsonData(tableData, state.filterColumn, state.filterText, state.filterDataType);
                }
                if (state.sortColumn) {
                    tableData = CommonService.sortJsonData(tableData, state.sortColumn, state.sortOrder);
                }

                newState.tableData = tableData;
                newState.prevTableData = props.tableData;
                if (tableData && state.maxRows > tableData.length) {
                    newState.maxRows = props.tablePageSize;
                }
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            tableData: props.tableData,
            prevTableData: props.tableData,
            showFilter: false,
            showCancelFilter: false,
            filterText: "",
            filterColumn: null,
            filterDataType: null,
            filterTop: null,
            filterLeft: null,
            sortColumn: props.defaultSortColumn,
            sortOrder: props.defaultSortOrder,
            maxRows: props.tablePageSize,
            showEdit: false,
            showEditDropdown: false,
            editRowData: null,
            editColumnComponent: null,
            editHeaderText: "",
            editTop: null,
            editLeft: null,
            editText: "",
            showFloatingHeader: "d-none",
            floatingHeaderTop: "0px",
            floatingHeaderWidth: "0px",
            showFloatingColumn: "d-none",
            floatingColumnTop: "0px",
            floatingColumnLeft: "0px",
            rowKeySeed: 0
        };

        this.onSortClick = this.onSortClick.bind(this);
        this.onShowFilterClick = this.onShowFilterClick.bind(this);
        this.onHideFilterClick = this.onHideFilterClick.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onCancelFilterClick = this.onCancelFilterClick.bind(this);
        this.onShowEditClick = this.onShowEditClick.bind(this);
        this.onHideEditClick = this.onHideEditClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onCellClick = this.onCellClick.bind(this);
        this.onDdpItemClick = this.onDdpItemClick.bind(this);
        this.onDdpSaveClick = this.onDdpSaveClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.onTableScroll = this.onTableScroll.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.onDocumentClick.bind(this));
        if (this.props.stickyHeader || this.props.tablePageSize) {
            if (this.props.scrollElement) {
                $(this.props.scrollElement)[0].addEventListener("scroll", this.onTableScroll.bind(this));
            }
            else {
                $(".dgdg-site-scrollable-content")[0].addEventListener("scroll", this.onTableScroll.bind(this));
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = false;

        if (nextProps.paintTable === undefined) {
            shouldUpdate = true;
        }
        else {
            // Force repaint on filter
            if (this.state.showFilter !== nextState.showFilter
                || this.state.showCancelFilter !== nextState.showCancelFilter
                || nextState.forceRepaint) {
                shouldUpdate = true;
            }
            // Force repaint on sort
            else if (this.state.sortColumn !== nextState.sortColumn
                || this.state.sortOrder !== nextState.sortOrder) {
                shouldUpdate = true;
            }
            // Force repaint on infinite scroll
            else if (this.state.maxRows !== nextState.maxRows) {
                shouldUpdate = true;
            }
            // Force repaint on edit box show
            else if (this.state.showEdit !== nextState.showEdit) {
                shouldUpdate = true;
            }
            else if (this.state.showEditDropdown !== nextState.showEditDropdown) {
                shouldUpdate = true;
            }
            else if (this.state.floatingHeaderTop !== nextState.floatingHeaderTop
                || this.state.floatingHeaderWidth !== nextState.floatingHeaderWidth
                || this.state.showFloatingHeader !== nextState.showFloatingHeader) {
                shouldUpdate = true;
            }
            else if (this.state.showFloatingColumn !== nextState.showFloatingColumn
                || this.state.floatingColumnTop !== nextState.floatingColumnTop
                || this.state.floatingColumnLeft !== nextState.floatingColumnLeft) {
                shouldUpdate = true;
            }
            // Repaint if required
            else if (nextProps.paintTable !== undefined) {
                shouldUpdate = nextProps.paintTable;
            }
        }

        return shouldUpdate;
    }

    componentDidUpdate(prevProps, prevState) {
        let rowComponents = null;
        if (Array.isArray(this.props.children)) {
            rowComponents = this.props.children.filter(item => item.type === DGDGTableRowComponent);
        }
        else {
            rowComponents = [this.props.children];
        }

        rowComponents.forEach((rowComponent) => {
            let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
            columnComponents.forEach((columnComponent, columnIndex) => {
                if (columnComponent) {
                    if (columnComponent.type === DGDGTableColumnSparklineComponent) {
                        if (this.state.tableData) {
                            this.state.tableData.forEach((rowData, rowIndex) => {
                                let values = [];
                                if (columnComponent.props.valueFields) {
                                    columnComponent.props.valueFields.forEach((valueField, index) => {
                                        values.push(rowData[valueField]);
                                    });
                                }

                                let sparkLineId = "sparkline_" + rowIndex + "_" + columnIndex;
                                $(this[sparkLineId]).sparkline(values, columnComponent.props.options);
                            });
                        }
                    }
                }
            });
        });
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.onDocumentClick);
        if (this.props.stickyHeader || this.props.tablePageSize) {
            $(".dgdg-site-scrollable-content")[0].removeEventListener("scroll", this.onTableScroll);
        }
    }


    renderColumns(rowComponent, rowData, rowIndex) {
        let tableColumns = [];
        let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
        let columnSpan = 0;
        columnComponents.forEach((columnComponent, columnIndex) => {
            if (columnSpan > 1) {
                columnSpan--;
                return;
            }

            if (rowData[columnComponent.props.dataColumn + "_colSpan"]) {
                columnSpan = parseInt(rowData[columnComponent.props.dataColumn + "_colSpan"]);
            }

            let cssName = "";
            let cellStyle = this.getCellStyle(rowData, columnComponent.props.backgroundColor, columnComponent.props.backgroundColorPct, columnComponent.props.dataColumn + "_color_strips", columnComponent.props.backgroundSize, columnComponent.props.color);
            if (rowData[columnComponent.props.dataColumn + "_hideCell"]) {
                tableColumns.push(<td key={"column" + columnIndex} className={cssName} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan} onClick={(event) => this.onCellClick(event, columnComponent, rowData)} />);
            }
            else {
                if (columnComponent.type === DGDGTableColumnSparklineComponent) {
                    cssName = this.getColumnCss(columnComponent, rowData[columnComponent.props.dataColumn + "_cellCssName"], rowIndex);
                    tableColumns.push(<td key={"column" + columnIndex} className={cssName} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <div ref={refElement => this["sparkline_" + rowIndex + "_" + columnIndex] = refElement} />
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <DGDGSpinnerComponent cssName="float-right" showSpinner />
                                : null
                        }
                    </td>);
                }
                else if (columnComponent.type === DGDGTableColumnCheckboxComponent) {
                    cssName = this.getColumnCss(columnComponent, rowData[columnComponent.props.dataColumn + "_cellCssName"], rowIndex);
                    tableColumns.push(<td key={"column" + columnIndex} className={cssName} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <DGDGFormCheckboxComponent id={columnComponent.props.dataColumn + "-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData}
                            disabled={rowData[columnComponent.props.disabledColumn]} value={rowData[columnComponent.props.dataColumn]}
                            onChange={columnComponent.props.onChange}
                        />
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <DGDGSpinnerComponent cssName="float-right" showSpinner />
                                : null
                        }
                    </td>);
                }
                else if (columnComponent.type === DGDGTableColumnCheckboxV2Component) {
                    cssName = this.getColumnCss(columnComponent, rowData[columnComponent.props.dataColumn + "_cellCssName"], rowIndex);
                    tableColumns.push(<td key={"column" + columnIndex} className={cssName} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <DGDGFormCheckboxV2Component id={columnComponent.props.dataColumn + "-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData}
                            disabled={rowData[columnComponent.props.disabledColumn]} value={rowData[columnComponent.props.dataColumn]} label={rowData[columnComponent.props.labelColumn]} label2={rowData[columnComponent.props.label2Column]}
                            onChange={columnComponent.props.onChange}
                        />
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <DGDGSpinnerComponent cssName="float-right" showSpinner />
                                : null
                        }
                    </td>);
                }
                else if (columnComponent.type === DGDGTableColumnDropdownComponent) {
                    cssName = this.getColumnCss(columnComponent, rowData[columnComponent.props.dataColumn + "_cellCssName"], rowIndex);
                    let selectedText = rowData[columnComponent.props.dataColumn] ? rowData[columnComponent.props.dataColumn] : columnComponent.props.placeholder;
                    tableColumns.push(<td key={"column" + columnIndex} className={cssName} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <DGDGFormDropdownComponent id={columnComponent.props.dataColumn + "-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData}
                            dropDownCss={columnComponent.props.dropdownCss}
                            disabled={rowData[columnComponent.props.disabledColumn]} data={columnComponent.props.data} itemKey={columnComponent.props.itemKey} value={selectedText ? selectedText : "Select Value"}
                            inputCssName={rowData[columnComponent.props.dataColumn + "_inputCssName"]}
                            onItemClick={columnComponent.props.onItemClick} onKeyDown={columnComponent.props.onKeyDown}
                        />
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <DGDGSpinnerComponent cssName="float-right" showSpinner />
                                : null
                        }
                    </td>);
                }
                else if (columnComponent.type === DGDGTableColumnInputComponent) {
                    cssName = this.getColumnCss(columnComponent, rowData[columnComponent.props.dataColumn + "_cellCssName"], rowIndex);
                    tableColumns.push(<td key={"column" + columnIndex} className={cssName} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <DGDGFormInputComponent id={columnComponent.props.dataColumn + "-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData} enterToTab
                            disabled={rowData[columnComponent.props.dataColumn + "_disabled"]} readOnly={rowData[columnComponent.props.dataColumn + "_readOnly"]} placeholder={columnComponent.props.placeholder} value={rowData[columnComponent.props.dataColumn] ? rowData[columnComponent.props.dataColumn] : ""}
                            inputType={columnComponent.props.inputType} controlCssName={columnComponent.props.controlCssName}
                            onChange={columnComponent.props.onChange} onKeyDown={columnComponent.props.onKeyDown} onClearClick={columnComponent.props.onClearClick} onLookupClick={columnComponent.props.onLookupClick}
                        />
                    </td>);
                }
                else {
                    let data = "";

                    // Get the cell data
                    if (columnComponent.props.dataText) {
                        data = <span dangerouslySetInnerHTML={{ __html: columnComponent.props.dataText }} />;
                    }
                    else if (columnComponent.type === DGDGTableColumnDrCrComponent) {
                        let amount = rowData[columnComponent.props.dataColumn];
                        if (amount > 0) {
                            data = "DR";
                        }
                        else if (amount < 0) {
                            data = "CR";
                        }
                    }
                    else {
                        data = columnComponent.props.dataColumnCallback
                            ? columnComponent.props.dataColumnCallback(rowData, columnComponent.props.dataColumn)
                            : this.getData(rowData, columnComponent.props.dataColumn);
                    }

                    // Apply dataType formatting
                    if (columnComponent.props.isDrCr) {
                        data = Math.abs(data);
                    }

                    if (columnComponent.props.dataType === "accounting_amount" || columnComponent.props.dataType === "accounting_amount_simple") {
                        data = Math.abs(data);
                    }

                    if (columnComponent.props.dataType && !columnComponent.props.dataColumnCallback) {
                        data = this.formatData(columnComponent.props.dataType, data);
                    }

                    if (columnComponent.props.ofDataColumn) {
                        data = data + " (" + this.formatData("percent", rowData[columnComponent.props.ofDataColumn]) + ")";
                    }

                    let dataTitle = data;
                    if (columnComponent.props.maxLength && data && data.length > columnComponent.props.maxLength) {
                        data = data.substring(0, columnComponent.props.maxLength - 3);
                    }

                    // Apply cell formatting
                    cssName = this.getColumnCss(columnComponent, rowData[columnComponent.props.dataColumn + "_cellCssName"], rowIndex);
                    let negetiveAmountCssName = "";
                    let amount = rowData[columnComponent.props.dataColumn];
                    if ((columnComponent.props.dataType === "formatted_number_2" || columnComponent.props.dataType === "money") && amount < 0) {
                        cssName += (columnComponent.props.dataType === "formatted_number_2" ? " dgdg-font-red" : "")
                        negetiveAmountCssName = " dgdg-font-red";
                    }
                    let tdRef = "column-" + rowIndex + "-" + columnIndex;
                    tableColumns.push(<td key={"column" + columnIndex} ref={refElement => this[tdRef] = refElement} className={cssName} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan} onClick={(event) => this.onCellClick(event, columnComponent, rowData)}>
                        {
                            columnComponent.props.isEditable
                                ? <Fragment>
                                    <button type="button" className="btn btn-link" onClick={(event) => this.onShowEditClick(event, tdRef, rowData, columnComponent)} disabled={rowData[columnComponent.props.disabledColumn]}>
                                        <span className="fas fa-edit" />
                                    </button>
                                    &nbsp;&nbsp;
                                </Fragment>
                                : null
                        }
                        {
                            columnComponent.props.dataType === "anchor"
                                ? <a href={rowData[columnComponent.props.href]} target={columnComponent.props.target}>{data}</a>
                                : columnComponent.props.onCellClick
                                    ? <button type="button" className={"btn btn-link text-left" + negetiveAmountCssName} onClick={(event) => columnComponent.props.onCellClick(event, rowData, columnComponent.props.cellData)}>{data}</button>
                                    : <Fragment>
                                        {
                                            columnComponent.props.showHeader
                                                ? <span className="font-weight-bold" dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText + ": " }} />
                                                : null
                                        }
                                        {data}
                                        {
                                            columnComponent.props.maxLength && data && dataTitle.length > columnComponent.props.maxLength
                                                ? <span data-toggle="tooltip" title={dataTitle}>...</span>
                                                : null
                                        }
                                    </Fragment>
                        }
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <DGDGSpinnerComponent cssName="float-right" showSpinner />
                                : null
                        }
                    </td>);
                }
            }
        });

        return tableColumns;
    }

    getData(rowData, dataColumn) {
        let data;
        if (dataColumn) {
            let dataColumns = dataColumn.split(".");
            if (dataColumns.length > 1 && rowData[dataColumns[0]]) {
                data = rowData;
                dataColumns.forEach((columnName) => {
                    data = data[columnName];
                });
            }
            else {
                data = rowData[dataColumn];
            }

            if (Array.isArray(data)) {
                data = data.join(", ");
            }
        }

        return data;
    }

    formatData(dataType, data) {
        if (data === undefined || data === null) {
            return "";
        }

        switch (dataType) {
            case "number":
                data = Math.round(data);
                break;

            case "number_1":
                data = (Math.round(data * 10) / 10).toFixed(1);
                break;

            case "number_2":
                data = (Math.round(data * 100) / 100).toFixed(2);
                break;

            case "number_3":
                data = (Math.round(data * 1000) / 1000).toFixed(3);
                break;

            case "date":
                data = CommonService.formatDate(moment(data));
                break;

            case "date_mmddyy":
                data = CommonService.formatDate_mmddyy(moment(data, "MMDDYY", true));
                break;

            case "date_mmddyyyy":
                data = CommonService.formatDate_mmddyyyy(moment(data, "YYYY-MM-DD"));
                break;

            case "dateTime":
                data = CommonService.formatDateTime(moment(data));
                break;

            case "dateTime_2":
                data = CommonService.formatDateTime_2(moment(data));
                break;

            case "shortMonth":
                data = CommonService.formatMonth(moment().date(1).month(data - 1).year(2017));
                break;

            case "time":
                data = CommonService.formatTime(moment(data));
                break;

            case "bool":
                data = CommonService.formatBoolString(data);
                break;

            case "money":
                data = CommonService.formatCurrency(data);
                break;

            case "signed_money":
                data = CommonService.formatSignedCurrency(data);
                break;

            case "money_2":
                data = CommonService.formatCurrency_2(data);
                break;

            case "money_2_simple":
                data = CommonService.formatCurrency_2_simple(data);
                break;

            case "accounting_amount":
                data = CommonService.formatAccountingAmount(data);
                break;

            case "accounting_amount_simple":
                data = CommonService.formatAccountingAmount_simple(data);
                break;

            case "percent":
                data = CommonService.formatPercentage(data);
                break;

            case "signed_percent":
                data = CommonService.formatSignedPercentage(data);
                break;

            case "percent_1":
                data = CommonService.formatPercentage_1(data);
                break;

            case "percent_2":
                data = CommonService.formatPercentage_2(data);
                break;

            case "formatted_number":
                data = CommonService.formatNumber(data);
                break;

            case "formatted_number_2":
                data = CommonService.formatNumber_2(data);
                break;

            case "html":
                data = <span dangerouslySetInnerHTML={{ __html: data }} />
                break;

            case "phone":
                data = CommonService.formatPhoneNumber(data, false);
                break;
            default:
                break;
        }

        return data;
    }

    getColumnCss(columnComponent, cellCssName, rowIndex) {
        let cssName = columnComponent.props.cssName ? columnComponent.props.cssName : "";
        cssName += cellCssName ? " " + cellCssName : "";
        if (!columnComponent.props.isEditable && (columnComponent.props.dataType === "number" || columnComponent.props.dataType === "number_1" || columnComponent.props.dataType === "number_2" || columnComponent.props.dataType === "number_3"
            || columnComponent.props.dataType === "formatted_number" || columnComponent.props.dataType === "formatted_number_2"
            || columnComponent.props.dataType === "money" || columnComponent.props.dataType === "signed_money" || columnComponent.props.dataType === "money_2" || columnComponent.props.dataType === "money_2_simple"
            || columnComponent.props.dataType === "accounting_amount" || columnComponent.props.dataType === "accounting_amount_simple"
            || columnComponent.props.dataType === "percent" || columnComponent.props.dataType === "signed_percent" || columnComponent.props.dataType === "percent_1" || columnComponent.props.dataType === "percent_2")) {

            cssName += " text-right";
        }

        if (columnComponent.props.columnBand1 && rowIndex % 2 === 0) {
            cssName += " " + columnComponent.props.columnBand1;
        }

        if (columnComponent.props.columnBand2 && rowIndex % 2 !== 0) {
            cssName += " " + columnComponent.props.columnBand2;
        }

        if (!this.props.showFooter && rowIndex === this.state.tableData.length - 1) {
            cssName += columnComponent.props.footerCssName === undefined ? "" : " " + columnComponent.props.footerCssName;
        }

        return cssName;
    }

    getCellStyle(rowData, backgroundColor, backgroundColorPct, colorStrips, backgroundSize, color) {
        let cellStyle = {};
        if (rowData && backgroundColorPct) {
            cellStyle.background = "linear-gradient(to right, " + rowData[backgroundColor] + " " + rowData[backgroundColorPct] + "%, transparent " + rowData[backgroundColorPct] + "%)";
        }

        else if (rowData && backgroundColor && rowData[colorStrips]?.length > 0) {
            cellStyle.background = "linear-gradient(to right, ";
            let backgroundColorStripStartPct = 0;
            let backgroundColorStripEndPct = 3;
            rowData[colorStrips].forEach((colorStrip, index) => {
                cellStyle.background += colorStrip + " " + backgroundColorStripStartPct + "% " + backgroundColorStripEndPct + "%, ";
                if (index !== rowData[colorStrips].length - 1) {
                    backgroundColorStripStartPct = backgroundColorStripEndPct;
                    backgroundColorStripEndPct += 3;
                }
            });
            cellStyle.background += (rowData[backgroundColor]) + " " + backgroundColorStripEndPct + "% 100%)";
        }

        else if (rowData && backgroundColor && backgroundSize) {
            cellStyle.backgroundImage = "linear-gradient(to right, " + rowData[backgroundColor] + ", " + rowData[backgroundColor] + ")";
            cellStyle.backgroundRepeat = "no-repeat";
            cellStyle.backgroundSize = rowData[backgroundSize] + "% 100%";
        }

        else if (rowData && backgroundColor) {
            cellStyle.backgroundColor = rowData[backgroundColor];
        }

        if (rowData && color) {
            cellStyle.color = rowData[color];
        }

        return cellStyle;
    }

    getAllColumnComponents(rowComponent) {
        if (!Array.isArray(rowComponent)) {
            rowComponent = [rowComponent];
        }

        return rowComponent;
    }

    getVisibleColumnComponents(rowComponent) {
        rowComponent = this.getAllColumnComponents(rowComponent);
        rowComponent = rowComponent.filter(columnComponent => columnComponent && !columnComponent.props.isHidden);
        return rowComponent;
    }

    getFooterValue(columnComponent) {
        let footerValue = null;
        if (columnComponent.props.footerText) {
            footerValue = columnComponent.props.footerText;
        } else if (this.props.footerRow && columnComponent.props.dataColumn) {
            footerValue = this.getData(this.props.footerRow, columnComponent.props.dataColumn);
            footerValue = this.formatData(columnComponent.props.dataType, footerValue);
        }
        else if (columnComponent.props.footerFunction) {
            switch (columnComponent.props.footerFunction) {
                case "sum":
                    footerValue = 0;
                    this.state.tableData.forEach((rowData) => {
                        let value = parseFloat(this.getData(rowData, columnComponent.props.dataColumn));
                        if (!isNaN(value)) {
                            footerValue += value;
                        }
                    });
                    break;

                case "average":
                    footerValue = 0;
                    if (this.state.tableData.length > 0) {
                        this.state.tableData.forEach((rowData) => {
                            let value = parseFloat(this.getData(rowData, columnComponent.props.dataColumn));
                            if (!isNaN(value)) {
                                footerValue += value;
                            }
                        });

                        footerValue = footerValue / this.state.tableData.length;
                    }
                    break;

                default:
                    break;
            }

            if (columnComponent.props.isDrCr) {
                footerValue = Math.abs(footerValue);
            }

            if (columnComponent.type === DGDGTableColumnDrCrComponent) {
                if (!columnComponent.props.isDrCrHidden) {
                    if (footerValue > 0) {
                        footerValue = "DR";
                    }
                    else if (footerValue < 0) {
                        footerValue = "CR";
                    }
                }
                else {
                    footerValue = "";
                }
            }
            else {
                footerValue = this.formatData(columnComponent.props.dataType, footerValue);
            }
        }

        return footerValue;
    }

    prepareHeader(rowComponent) {
        let headerRow = [];
        let headerColSpan = 0;
        let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
        columnComponents.forEach((columnComponent, index) => {
            if (headerColSpan > 1) {
                headerColSpan--;
                return;
            }

            if (columnComponent) {
                if (columnComponent.props.headerColSpan) {
                    headerColSpan = parseInt(columnComponent.props.headerColSpan);
                }

                if (columnComponent.type === DGDGTableColumnCheckboxComponent) {
                    headerRow.push(<td key={"header" + index} ref={refElement => this["header-cell" + index] = refElement} className={"text-center " + (columnComponent.props.headerCssName === undefined ? "" : columnComponent.props.headerCssName)} colSpan={columnComponent.props.headerColSpan}>
                        <DGDGFormCheckboxComponent id={columnComponent.props.dataColumn + "_selectAll"} disabled={columnComponent.props.disableSelectAll} value={columnComponent.props.selectAll} onChange={columnComponent.props.onChangeAll} />
                    </td>);
                }
                //else if (columnComponent.type === DGDGTableColumnCheckboxV2Component) {
                //    headerRow.push(<td key={"header" + index} ref={refElement => this["header-cell" + index] = refElement} className={"text-center " + (columnComponent.props.headerCssName === undefined ? "" : columnComponent.props.headerCssName)} colSpan={columnComponent.props.headerColSpan}>
                //        <DGDGFormCheckboxV2Component id={columnComponent.props.dataColumn + "_selectAll"} disabled={columnComponent.props.disableSelectAll} value={columnComponent.props.selectAll} onChange={columnComponent.props.onChangeAll} />
                //    </td>);
                //}
                else {
                    headerRow.push(<td key={"header" + index} ref={refElement => this["header-cell" + index] = refElement} className={"text-center " + (columnComponent.props.headerCssName === undefined ? "" : columnComponent.props.headerCssName)} colSpan={columnComponent.props.headerColSpan}>
                        {
                            columnComponent.props.sortColumn
                                ? Array.isArray(columnComponent.props.sortColumn)
                                    ? <div className="d-inline dropdown">
                                        <a href={(event) => false} role="button" className="font-weight-bold" id="sortList" data-toggle="dropdown" aria-expanded="false">
                                            <span dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText }} style={{ marginRight: "5px" }} />
                                            {
                                                columnComponent.props.sortColumn.filter(sortCol => sortCol.column === this.state.sortColumn).length === 1
                                                    ? <Fragment>
                                                        {!columnComponent.props.sortColumn[0]?.hideSortDisplayName ? columnComponent.props.sortColumn.filter(sortCol => sortCol.column === this.state.sortColumn)[0].displayName : ""}
                                                        {
                                                            this.state.sortOrder === "Asc"
                                                                ? <span style={{ marginLeft: "5px" }} className="far fa-sort-up" />
                                                                : <span style={{ marginLeft: "5px" }} className="far fa-sort-down" />
                                                        }
                                                    </Fragment>
                                                    : null
                                            }
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="sortList">
                                            {
                                                columnComponent.props.sortColumn.map((sortProp, index) => (
                                                    <button key={"dropdown-item-" + index} className="btn btn-link dropdown-item" onClick={(event) => this.onSortClick(event, columnComponent, sortProp)}>{sortProp.displayName}
                                                        {
                                                            this.state.sortColumn === sortProp.column
                                                                ? this.state.sortOrder === "Asc"
                                                                    ? <span style={{ marginLeft: "5px" }} className="far fa-sort-up" />
                                                                    : <span style={{ marginLeft: "5px" }} className="far fa-sort-down" />
                                                                : null
                                                        }
                                                    </button>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    : <a href={(event) => false} role="button" className="font-weight-bold" onClick={(event) => this.onSortClick(event, columnComponent)}>
                                        <span dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText }} />
                                        {
                                            this.state.sortColumn === columnComponent.props.sortColumn
                                                ? this.state.sortOrder === "Asc"
                                                    ? <span style={{ marginLeft: "5px" }} className="far fa-sort-up" />
                                                    : <span style={{ marginLeft: "5px" }} className="far fa-sort-down" />
                                                : null
                                        }
                                    </a>
                                : <span dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText }} />
                        }
                        {
                            columnComponent.props.filterColumn
                                ? this.state.filterColumn === columnComponent.props.filterColumn
                                    ? <button type="button" className="btn btn-link font-weight-bold" onClick={(event) => this.onCancelFilterClick(event)}>
                                        <span style={{ textDecoration: "line-through", marginLeft: "5px" }} className="fas fa-filter" />
                                    </button>
                                    : <button type="button" className="btn btn-link font-weight-bold" onClick={(event) => this.onShowFilterClick(event, "header-cell" + index, columnComponent.props.filterColumn, columnComponent.props.dataType)}>
                                        <span style={{ marginLeft: "5px" }} className="far fa-filter" />
                                    </button>
                                : null
                        }
                        {
                            columnComponent.props.headerCallback
                                ? columnComponent.props.headerCallback(columnComponent)
                                : null
                        }
                        {
                            columnComponent.props.columnHelp
                                ? <Fragment>
                                    <br />
                                    <div className="tooltip" data-display="static">
                                        <span className="far fa-info-circle" style={{ "color": "black", "fontWeight": "500" }} data-display="static" />
                                        <div className={"content " + (columnComponent.props.columnHelpCssName ? columnComponent.props.columnHelpCssName : "")} data-display="static">
                                            {columnComponent.props.columnHelp}
                                        </div>
                                    </div>
                                </Fragment>
                                : null
                        }
                    </td>);
                }
            }
        });

        return <tr id="dgdgRwHeader" key="row-header" className="dgdg-table-v3-header font-weight-bold">
            {headerRow}
        </tr>;
    }

    prepareFooter(rowComponent) {
        let footerRow = [];
        if (this.props.showFooter) {
            let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
            columnComponents.forEach((columnComponent, columnIndex) => {
                let footerValue = this.getFooterValue(columnComponent);
                let cssName = columnComponent.props.footerCssName === undefined ? "" : columnComponent.props.footerCssName;
                let cellStyle = this.getCellStyle(this.props.footerRow, columnComponent.props.backgroundColor, columnComponent.props.backgroundColorPct, columnComponent.props.dataColumn + "_color_strips", columnComponent.props.backgroundSize, columnComponent.props.color);
                footerRow.push(<td key={"footer" + columnIndex} className={cssName} style={cellStyle}>
                    {
                        columnComponent.props.onFooterCellClick
                            ? <button type="button" className="btn btn-link font-weight-bold" onClick={(event) => columnComponent.props.onFooterCellClick(event, this.props.footerRow)}>{footerValue}</button>
                            : columnComponent.props.onFooterCallback
                                ? columnComponent.props.onFooterCallback(this.props.footerRow, columnComponent.props.dataColumn)
                                : footerValue
                    }
                </td>);
            });
            //}
        }

        return this.props.showFooter
            ? <tr key="row-footer" className="dgdg-row-bg-head-foot font-weight-bold">
                {footerRow}
            </tr>
            : null;
    }

    onSortClick(event, columnComponent, sortProps) {
        let columnSortOrder = "Asc";
        if (columnComponent.props.firstSortOrder)
            columnSortOrder = columnComponent.props.firstSortOrder;

        let sortColumn = columnComponent.props.sortColumn;
        if (sortProps) {
            sortColumn = sortProps.column;
            if (sortProps.firstSortOrder) {
                columnSortOrder = sortProps.firstSortOrder;
            }
        }

        if (this.state.sortColumn && this.state.sortColumn === sortColumn) {
            columnSortOrder = this.state.sortOrder;
            if (columnSortOrder === "Desc") {
                columnSortOrder = "Asc";
            } else {
                columnSortOrder = "Desc";
            }
        }

        // When infinite scroll is enabled. Pick the tableData to sort ons
        let tableData = this.props.tableData; // Full data set
        if (this.state.filterText !== "") {
            tableData = this.state.tableData; // Filtered data
        }

        let sortedTableData = CommonService.sortJsonData(tableData, sortColumn, columnSortOrder, columnComponent.props.dataType);
        let rowKeySeed = this.state.rowKeySeed + 1;
        this.setState({
            rowKeySeed: rowKeySeed,
            tableData: sortedTableData,
            sortColumn: sortColumn,
            sortOrder: columnSortOrder
        });

        if (this.props.onSortChange) {
            this.props.onSortChange(sortColumn, columnSortOrder, columnComponent.props.dataType);
        }
    }

    onShowFilterClick(event, headerCellRef, fltrColumn, dtType) {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        else if (window.event) {
            window.event.cancelBubble = true;
        }

        let dgdgTable = ReactDOM.findDOMNode(this.dgdgTable);
        let headerCell = ReactDOM.findDOMNode(this[headerCellRef]);
        this.setState({
            showFilter: true,
            showCancelFilter: true,
            filterColumn: fltrColumn,
            filterDataType: dtType,
            filterTop: dgdgTable.offsetTop,
            filterLeft: dgdgTable.offsetLeft + headerCell.offsetLeft + headerCell.offsetWidth - 15
        });
    }

    onHideFilterClick(event) {
        if (this.state.showFilter) {
            let showCancelFilter = this.state.showCancelFilter;
            let filterColumn = this.state.filterColumn;
            if (this.state.filterText === "") {
                showCancelFilter = false;
                filterColumn = "";
            }

            this.setState({
                showFilter: false,
                showCancelFilter: showCancelFilter,
                filterColumn: filterColumn
            });
        }
    }

    onFilterClick(event, filterText, filterOption) {
        let filteredTableData = CommonService.filterJsonData(this.props.tableData, this.state.filterColumn, filterText, this.state.filterDataType, filterOption);
        this.setState({
            tableData: [],
            forceRepaint: true
        }, () => {
            this.setState({
                tableData: filteredTableData,
                filterText: filterText,
                showFilter: false,
                forceRepaint: false
            });
        });
    }

    onCancelFilterClick(event) {
        this.setState({
            tableData: this.props.tableData,
            filterColumn: null,
            filterText: "",
            showFilter: false,
            showCancelFilter: false
        });
    }

    onDocumentClick(event) {
        let targetCss = "";
        if (event.target) {
            targetCss = event.target.getAttribute("class");
        }
        if (targetCss && targetCss.indexOf("dropdown-toggle") === -1 && targetCss.indexOf("dropdown-item") === -1) {
            this.onHideFilterClick(event);
            this.onHideEditClick(event);
        }
    }

    onTableScroll(event) {
        let dgdgTable = ReactDOM.findDOMNode(this.dgdgTable);
        if (dgdgTable) {
            let dgdgPanelRect = event.target.getBoundingClientRect();
            let dgdgTableRect = dgdgTable.getBoundingClientRect();
            if (this.props.tablePageSize && dgdgTable?.childNodes?.length > 1) {
                let lastRow = dgdgTable.childNodes[dgdgTable.childNodes.length - 1].getBoundingClientRect();
                if (lastRow.height === 0) {
                    lastRow = dgdgTable.childNodes[dgdgTable.childNodes.length - 2].getBoundingClientRect();
                }

                if (this.props.tablePageSize && (lastRow.bottom < dgdgPanelRect.bottom)) {
                    if (this.state.maxRows && this.state.tableData && this.state.maxRows < this.state.tableData.length) {
                        let maxRows = this.state.maxRows + this.props.tablePageSize;
                        if (maxRows > this.state.tableData.length) {
                            maxRows = this.state.tableData.length;
                        }

                        this.setState({ maxRows: maxRows });
                    }
                }
            }

            if (this.props.stickyHeader) {
                let firstRow = dgdgTable.childNodes[0].getBoundingClientRect();
                let floatingRwCard = $(this.dgdgFloatingRwHeader).find("#floatingRwCard")[0].getBoundingClientRect();
                if (firstRow.top < dgdgPanelRect.top && dgdgTableRect.bottom - 100 > dgdgPanelRect.top) {
                    let dgdgFloatingRwHeader = $(this.dgdgFloatingRwHeader).find("#dgdgRwHeader")[0];
                    $(dgdgTable).find("#dgdgRwHeader")[0].childNodes.forEach((headerCell, cellIndex) => {
                        $(dgdgFloatingRwHeader.childNodes[cellIndex]).width($(headerCell).width());
                    });
                    // floatingRwCard
                    this.setState({
                        floatingHeaderWidth: (dgdgTableRect.width + 10) + "px",
                        floatingHeaderTop: Math.abs(dgdgPanelRect.top + floatingRwCard.height - firstRow.top) + "px",
                        showFloatingHeader: "d-block"
                    });
                }
                else {
                    this.setState({
                        showFloatingHeader: "d-none"
                    });
                }
            }

            if (this.props.freezeColumn) {
                if (dgdgTableRect.left < 0) {
                    let dgdgFloatingTBodies = $(this.dgdgFloatingTable).find("tbody");
                    let dgdgTBodies = $(dgdgTable).find("tbody");
                    dgdgTBodies.each(function (index) {
                        $(dgdgFloatingTBodies[index]).height($(this).height());
                    });
                    this.setState({
                        showFloatingColumn: "d-block",
                        floatingColumnLeft: Math.abs(dgdgTableRect.left) + "px",
                        floatingColumnTop: (dgdgTBodies[0].offsetTop) + "px"
                    });
                }
                else {
                    this.setState({
                        showFloatingColumn: "d-none"
                    });
                }
            }
        }
    }

    onShowEditClick(event, rowCellRef, rowData, columnComponent) {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        else if (window.event) {
            window.event.cancelBubble = true;
        }

        let data = this.getData(rowData, columnComponent.props.dataColumn);
        let editHeaderText = columnComponent.props.headerText;
        if (columnComponent.props.editHeaderColumn) {
            editHeaderText = this.getData(rowData, columnComponent.props.editHeaderColumn);
        }

        let dgdgTable = ReactDOM.findDOMNode(this.dgdgTable);
        let rowCell = ReactDOM.findDOMNode(this[rowCellRef]);
        let tBodyTop = $($(rowCell).parent()[0]).parent()[0].offsetTop;
        let tRowTop = $(rowCell).parent()[0].offsetTop;
        let editLeft = rowCell.offsetLeft + event.target.offsetWidth + 8;
        let editTop = tBodyTop + tRowTop;
        let editWidth = 100;
        if (columnComponent.props.dataType === "comment" || columnComponent.props.dataType === "html") {
            editWidth = 500;
        }

        if (editLeft + editWidth > dgdgTable.offsetWidth) {
            editLeft = dgdgTable.offsetWidth - editWidth;
        }

        let showEdit = true, showEditDropdown = false;

        if (columnComponent.props.data) {
            showEdit = false;
            showEditDropdown = true;
        }

        this.setState({
            showEdit: showEdit,
            showEditDropdown: showEditDropdown,
            editRowData: rowData,
            editColumnComponent: columnComponent,
            editHeaderText: editHeaderText,
            editTop: editTop,
            editLeft: editLeft,
            editText: data
        });
    }

    onHideEditClick(event) {
        if (this.state.showEdit || this.state.showEditDropdown) {
            this.setState({
                showEdit: false,
                showEditDropdown: false,
                editRowData: null,
                editColumnComponent: null,
                editHeaderText: "",
                editTop: null,
                editLeft: null,
                editText: ""
            });
        }
    }

    onEditClick(event, editText) {
        if (this.state.editColumnComponent && this.state.editColumnComponent.props && this.state.editColumnComponent.props.onChange) {
            this.state.editColumnComponent.props.onChange(event, editText, this.state.editRowData);
        }

        this.onHideEditClick(event);
    }

    onCellClick(event, columnComponent, rowData) {
        if (event.detail === 3 && columnComponent.props.filterColumn) {
            let filteredTableData = CommonService.filterJsonData(this.props.tableData, columnComponent.props.filterColumn, rowData[columnComponent.props.filterColumn], columnComponent.props.filterColumn);
            this.setState({
                tableData: [],
                forceRepaint: true
            }, () => {
                this.setState({
                    showCancelFilter: true,
                    filterColumn: columnComponent.props.filterColumn,
                    filterDataType: columnComponent.props.dataType,
                    tableData: filteredTableData,
                    filterText: rowData[columnComponent.props.filterColumn],
                    showFilter: false,
                    forceRepaint: false
                })
            });
        }
    }

    onDdpItemClick(event, editText) {
        if (this.state.editColumnComponent && this.state.editColumnComponent.props && this.state.editColumnComponent.props.onItemClick) {
            this.setState({
                editText: editText
            }, () => {
                this.state.editColumnComponent.props.onItemClick(event, editText, this.state.editRowData);
            })
        }
    }

    onDdpSaveClick(event, editText) {
        if (this.state.editColumnComponent && this.state.editColumnComponent.props && this.state.editColumnComponent.props.onSaveClick) {
            this.state.editColumnComponent.props.onSaveClick(event, editText, this.state.editRowData);
        }

        this.onHideEditClick(event);
    }

    onRowClick(rowComponent, rowData, rowIndex) {
        if (rowComponent.props.onRowClick) {
            rowComponent.props.onRowClick(rowComponent, rowData, rowIndex);
        }
    }

    onCopyClick(event) {
        try {
            let copyText = "";
            let rowComponents = null;
            let headerRowComponent = null;
            if (Array.isArray(this.props.children)) {
                rowComponents = this.props.children.filter(item => item.type === DGDGTableRowComponent);
                headerRowComponent = this.props.children.filter(item => item.type === DGDGTableRowComponent && item.props.hasHeader);
                if (headerRowComponent.length === 0) {
                    headerRowComponent = rowComponents[0];
                } else {
                    headerRowComponent = headerRowComponent[0];
                }
            }
            else {
                rowComponents = [this.props.children];
                headerRowComponent = rowComponents[0];
            }

            let columnComponents = this.getAllColumnComponents(headerRowComponent.props.children);

            // Header
            this.props.copyTableConfig.columns.forEach((columnIndex) => {
                copyText += columnComponents[columnIndex].props.headerText + "\t";
            });
            copyText = copyText.trim("\t");
            copyText += "\r\n";

            // Body
            this.state.tableData.forEach((rowData) => {
                this.props.copyTableConfig.columns.forEach((columnIndex) => {
                    if (columnComponents[columnIndex].props.dataText) {
                        copyText += columnComponents[columnIndex].props.dataText + "\t";
                    }
                    else if (columnComponents[columnIndex].type === DGDGTableColumnDrCrComponent) {
                        let amount = rowData[columnComponents[columnIndex].props.dataColumn];
                        if (amount > 0) {
                            copyText += "DR\t";
                        }
                        else {
                            copyText += "CR\t";
                        }
                    }
                    else {
                        let data = this.getData(rowData, columnComponents[columnIndex].props.dataColumn);

                        // Apply dataType formatting
                        if (columnComponents[columnIndex].props.isDrCr) {
                            data = Math.abs(data);
                        }

                        if (columnComponents[columnIndex].props.dataType && !columnComponents[columnIndex].props.dataColumnCallback) {
                            let dataType = columnComponents[columnIndex].props.dataType;
                            switch (dataType) {
                                case "accounting_amount":
                                    dataType = "money_2";
                                    break;

                                case "accounting_amount_simple":
                                    dataType = "money_2_simple";
                                    break;

                                default:
                                    break;
                            }

                            data = this.formatData(dataType, data);
                        }

                        if (columnComponents[columnIndex].props.ofDataColumn) {
                            data = data + " (" + this.formatData("percent", rowData[columnComponents[columnIndex].props.ofDataColumn]) + ")";
                        }

                        copyText += (data != null ? data : "") + "\t";
                    }
                });

                copyText = copyText.replace("<br/>", "").replace("\n", "");
                copyText = copyText.trim("\t");
                copyText += "\r\n";
            });

            // Footer
            if (this.props.showFooter) {
                this.props.copyTableConfig.columns.forEach((columnIndex) => {
                    let footerValue = this.getFooterValue(columnComponents[columnIndex]);
                    copyText += (footerValue ?? "") + "\t";
                });
            }
            copyText = copyText.trim("\t");
            navigator.clipboard.writeText(copyText);

            if (this.props.copyTableConfig.onCopyTableCallBack) {
                this.props.copyTableConfig.onCopyTableCallBack(event);
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        let preHeaderComponents = null, postHeaderComponents = null;
        let preFooterComponents = null, postFooterComponents = null;
        let rowComponents = null;
        let headerRowComponent = null;
        if (Array.isArray(this.props.children)) {
            preHeaderComponents = this.props.children.filter(item => item.type === DGDGTableHeaderComponent && item.props.position === "PreHeader");
            postHeaderComponents = this.props.children.filter(item => item.type === DGDGTableHeaderComponent && item.props.position === "PostHeader");
            rowComponents = this.props.children.filter(item => item.type === DGDGTableRowComponent || item.type === DGDGTableCustomRowComponent);
            headerRowComponent = this.props.children.filter(item => item.type === DGDGTableRowComponent && item.props.hasHeader);
            if (headerRowComponent.length === 0) {
                headerRowComponent = rowComponents[0];
            } else {
                headerRowComponent = headerRowComponent[0];
            }
            preFooterComponents = this.props.children.filter(item => item.type === DGDGTableFooterComponent && item.props.position === "PreFooter");
            postFooterComponents = this.props.children.filter(item => item.type === DGDGTableFooterComponent && item.props.position === "PostFooter");
        }
        else {
            rowComponents = [this.props.children];
            headerRowComponent = rowComponents[0];
        }

        let header = this.prepareHeader(headerRowComponent);
        let footer = this.prepareFooter(headerRowComponent);
        let rowsTBodies = [];
        let floatingColumnRowsTBodies = [];
        if (this.state.tableData) {
            let maxRows = this.state.tableData.length;
            if (this.state.maxRows) {
                maxRows = this.state.maxRows;
            }

            for (let rowIndex = 0; rowIndex < maxRows && rowIndex < this.state.tableData.length; rowIndex++) {
                let rowData = this.state.tableData[rowIndex];
                let rows = [];
                let floatingColumnRows = [];
                rowComponents.forEach((rowComponent, rowComponentIndex) => {
                    let rowCss = "";
                    if (rowComponent.props.cssName) {
                        rowCss += " " + rowComponent.props.cssName;
                    }
                    if (rowData.rowCssName) {
                        rowCss += " " + rowData.rowCssName;
                    }
                    if (rowData.lastRowCssName && rowComponentIndex === rowComponents.length - 1) {
                        rowCss += " " + rowData.lastRowCssName;
                    }

                    if (rowComponent.type === DGDGTableCustomRowComponent) {
                        let customRows = rowComponent.props.onGetCustomRowsCallback(rowComponent, rowData, rowIndex);
                        customRows.forEach((customRow) => {
                            rows.push(customRow);
                        });
                    }
                    else {
                        let tableColumns = this.renderColumns(rowComponent, rowData, rowIndex);
                        rows.push(<tr key={"row-" + this.state.rowKeySeed + "-" + rowIndex + "-" + rowComponentIndex} className={rowCss} role={rowComponent.props.onRowClick ? "button" : null} onClick={(event) => this.onRowClick(rowComponent, rowData, rowIndex)}>
                            {tableColumns}
                        </tr>);

                        if (this.props.freezeColumn) {
                            let freezeColumns = [];
                            let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
                            let index = 0, count = 0;
                            while (count < this.props.freezeColumn) {
                                freezeColumns.push(tableColumns[index]);
                                count += parseInt(columnComponents[index].props.colSpan ? columnComponents[index].props.colSpan : 1);
                                index++;
                            }

                            floatingColumnRows.push(<tr key={"row-" + rowIndex + "-" + rowComponentIndex} className={rowCss}>
                                {freezeColumns}
                            </tr>);
                        }
                    }
                });

                rowsTBodies.push(<tbody key={"body-" + rowIndex} className="dgdg-table-v3-row">{rows}</tbody>);
                floatingColumnRowsTBodies.push(<tbody key={"body-" + rowIndex} className="dgdg-table-v3-row">{floatingColumnRows}</tbody>);
            }
        }

        let cardCssName = "card d-inline-block ";
        cardCssName += this.props.cardCssName ?? "";

        let tableHeader = <div className="card-header">
            {this.props.headerText}
            {
                this.state.maxRows && this.state.tableData
                    ? <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;{"Showing " + CommonService.formatNumber(Math.min(this.state.maxRows, this.state.tableData.length)) + "/" + CommonService.formatNumber(this.state.tableData.length) + " rows"}
                    </span>
                    : null
            }
            &nbsp;&nbsp;&nbsp;&nbsp;<span className="dgdg-font-size-14" dangerouslySetInnerHTML={{ __html: this.props.headerText2 }} />
            {
                this.props.copyTableConfig
                    ? <button type="button" className="btn btn-primary dgdg-copy-button" onClick={(event) => this.onCopyClick(event)}>
                        Copy<span className="far fa-copy dgdg-icon" />
                    </button>
                    : null
            }
            {
                this.props.legendText
                    ? <span className="dgdg-table-legend">
                        {this.props.legendText}
                    </span>
                    : null
            }
            {
                this.props.onCloseClick
                    ? <button type="button" className="btn btn-link float-right" onClick={(event) => this.props.onCloseClick(event)}>
                        <span className="fas fa-window-close dgdg-font-red" />
                    </button>
                    : null
            }
            {
                this.state.showCancelFilter
                    ? <button type="button" className="btn btn-link float-right" onClick={(event) => this.onCancelFilterClick(event)}>
                        <span style={{ textDecoration: "line-through" }} className="fas fa-filter" />Cancel filter
                    </button>
                    : null
            }
        </div>;

        return <Fragment>
            <div ref={refElement => this.dgdgFloatingRwHeader = refElement} className={"position-absolute " + this.state.showFloatingHeader} style={{ "zIndex": "200", "backgroundColor": "white", "top": this.state.floatingHeaderTop, "width": this.state.floatingHeaderWidth }}>
                <div id="floatingRwCard" className={cardCssName} style={{ "width": "100%" }}>
                    {tableHeader}
                </div>
                <div style={{ "padding": "5px 5px 0px 5px" }}>
                    <table className={"dgdg-table-v3 " + (this.props.cssName ?? "")}>
                        <thead>
                            {
                                preHeaderComponents
                                    ? preHeaderComponents
                                    : null
                            }
                            {header}
                            {
                                postHeaderComponents
                                    ? postHeaderComponents
                                    : null
                            }
                        </thead>
                    </table>
                </div>
            </div>
            <div className={cardCssName}>
                {
                    this.props.headerText || this.props.onCloseClick || this.state.showCancelFilter || this.props.copyTableConfig
                        ? tableHeader
                        : null
                }
                <div className={"card-block " + (this.props.cardBlockCssName ?? "")}>
                    <div className="position-relative">
                        <div className="form-inline justify-content-center">
                            <DGDGSpinnerComponent showSpinner={this.props.showSpinner} />
                            {this.props.infoText ? this.props.infoText : ""}
                        </div>
                        <DGDGEditBoxComponent
                            applicationInsights={this.props.applicationInsights}
                            showEdit={this.state.showEdit}
                            top={this.state.editTop}
                            left={this.state.editLeft}
                            dataType={this.state.editColumnComponent ? this.state.editColumnComponent.props.dataType : ""}
                            headerText={this.state.editHeaderText}
                            text={this.state.editText}
                            icon="fas fa-edit"
                            placeholder=""
                            onHideClick={this.onHideEditClick}
                            onClick={this.onEditClick}
                        />
                        <DGDGEditDropdownComponent
                            id="editDropdown"
                            showEditDropdown={this.state.showEditDropdown}
                            top={this.state.editTop}
                            left={this.state.editLeft}
                            data={this.state.editColumnComponent ? this.state.editColumnComponent.props.data : null}
                            headerText={this.state.editHeaderText}
                            value={this.state.editText}
                            icon="fas fa-edit"
                            placeholder=""
                            onHideClick={this.onHideEditClick}
                            onItemClick={this.onDdpItemClick}
                            onSaveClick={this.onDdpSaveClick}
                        />
                        <DGDGTableFilterComponent applicationInsights={this.props.applicationInsights} showFilter={this.state.showFilter} filterColumn={this.state.filterColumn} dataType={this.state.filterDataType}
                            filterText={this.state.filterText}
                            top={this.state.filterTop}
                            left={this.state.filterLeft}
                            onFilterClick={this.onFilterClick}
                            onHideFilterClick={this.onHideFilterClick}
                        />
                        <div className={"position-absolute " + this.state.showFloatingColumn} style={{ "zIndex": "999", "top": this.state.floatingColumnTop, "left": this.state.floatingColumnLeft }}>
                            <table className={"dgdg-table-v3 " + (this.props.cssName ? this.props.cssName : "")} ref={refElement => this.dgdgFloatingTable = refElement}>
                                {floatingColumnRowsTBodies}
                            </table>
                        </div>
                        <table className={"dgdg-main-table dgdg-table-v3 " + (this.props.cssName ? this.props.cssName : "")} ref={refElement => this.dgdgTable = refElement}>
                            <thead>
                                {
                                    preHeaderComponents
                                        ? preHeaderComponents
                                        : null
                                }
                                {header}
                                {
                                    postHeaderComponents
                                        ? postHeaderComponents
                                        : null
                                }
                            </thead>
                            {rowsTBodies}
                            <tfoot>
                                {
                                    preFooterComponents
                                        ? preFooterComponents
                                        : null
                                }
                                {footer}
                                {
                                    postFooterComponents
                                        ? postFooterComponents
                                        : null
                                }
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>;
    }
}