import React from "react";
import ReactDOM from "react-dom";
import { GoogleCharts } from "google-charts";

export class DGDGChartLineComponent extends React.Component {
    constructor(props) {
        super(props);
        this.drawChart = this.drawChart.bind(this);
    }

    resizeObserver = null;

    componentDidMount() {
        try {
            this.resizeObserver = new ResizeObserver(() => { this.drawChart(); });
            this.resizeObserver.observe(ReactDOM.findDOMNode(this));
            GoogleCharts.load(this.drawChart, { packages: ["corechart"] });
        }
        catch (error) {
            console.devError(error)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props &&
            (!prevProps || JSON.stringify(prevProps) !== JSON.stringify(this.props))) {
            this.drawChart();
        }
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    drawChart() {
        if (this.props.chartOption && this.props.chartData
            && GoogleCharts.api && GoogleCharts.api.visualization && GoogleCharts.api.visualization.arrayToDataTable && GoogleCharts.api.visualization.LineChart) {

            let visualizationData = GoogleCharts.api.visualization.arrayToDataTable(this.props.chartData);
            if (this.props.indexToShow) {
                for (let index = visualizationData.getNumberOfColumns() - 1; index > 0; index--) {
                    if (!this.props.indexToShow.includes(index)) {
                        visualizationData.removeColumn(index);
                    }
                }
            }

            let visualization = new GoogleCharts.api.visualization.LineChart(ReactDOM.findDOMNode(this));
            this.props.chartOption.hAxis.ticks = visualizationData.getDistinctValues(0);
            visualization.draw(visualizationData, this.props.chartOption);
        }
    }

    render() {
        let className = "dgdg-widget-item" + (this.props.cssName ? " " + this.props.cssName : "");
        return <div className={className} />;
    }
}