import React from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormCalendarComponent } from "../common/dgdgcalendar/DGDGFormCalendarComponent";
import { DGDGFormCheckboxComponent } from "../common/dgdgcheckbox/DGDGFormCheckboxComponent";
import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGPageComponent } from "../common/dgdgpage/DGDGPageComponent";
import { DGDGSpinnerComponent } from "../common/dgdgspinner/DGDGSpinnerComponent";

export class CovidRouteComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            stores: [],
            selectedStore: null,
            first: "",
            last: "",
            dob: "",
            workEmail: "",
            vaccinationStatus: "",
            signed: "",
            signedDate: moment(),
            showError: false,
            submitted: false
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onStoreClick = this.onStoreClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onVaccinationStatus = this.onVaccinationStatus.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("CovidRouteComponent - componentDidMount");
            this.props.onCovidMount();
            this.getStores();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        try {
            console.devLog("CovidRouteComponent - componentWillUnmount");
            this.props.onCovidUnmount();
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "COVID Vaccination Status";

    getStores() {
        fetch("/Covid/GetStores")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ stores: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json().then(parsedError => {
                        console.devError(parsedError, null, this.props.applicationInsights);
                    })
                }
                else {
                    console.devError(notOKResponse, null, this.props.applicationInsights);
                }
            });
    }

    onDateChange(id, value) {
        try {
            this.setState({ [id]: moment(value) });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onStoreClick(event, store) {
        try {
            this.setState({ selectedStore: store });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            if (!this.state.selectedStore
                || !this.state.first
                || !this.state.last
                || !CommonService.isDateValid(this.state.dob)
                || !this.state.workEmail
                || !this.state.vaccinationStatus
                || !this.state.signed
                || !this.state.signedDate) {
                this.setState({ showError: true });
                return;
            }

            this.setState({
                showSpinner: true,
                showError: false
            });

            let signedDate = moment();
            let inputData = JSON.stringify({
                storeNumber: this.state.selectedStore.store_number,
                first: this.state.first,
                last: this.state.last,
                dob: CommonService.formatServerDateTime(this.state.dob),
                workEmail: this.state.workEmail,
                vaccinationStatus: this.state.vaccinationStatus,
                signed: this.state.signed,
                signedDate: signedDate.utc().format("MM/DD/YYYY hh:mm:ss A")
            });

            fetch("/Covid/Submit", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: inputData
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        signedDate: signedDate,
                        showSpinner: false,
                        submitted: true
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json().then(parsedError => {
                            console.devError(parsedError, null, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                    }
                    else {
                        console.devError(notOKResponse, null, this.props.applicationInsights);
                    }
                });
        }

        catch (error) {
            this.setState({ showProgress: false });
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVaccinationStatus(id, isChecked, status) {
        try {
            this.setState({ vaccinationStatus: status });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        if (this.state.submitted) {
            let signedDate = this.state.signedDate;
            //return <Redirect to={{ pathname: "/CovidSubmit", state: { signedDate: this.state.signedDate } }} />;
            return <Redirect to={{ pathname: "/CovidSubmit", state: { signedDate: signedDate.local().format("MM/DD/YYYY hh:mm:ss A") } }} />;
        }

        return <DGDGPageComponent>
            <center>
                <img src="images/Covid_Header.png" alt="COVID header" className="dgdg-covid-header" />
                <img src="images/Covid_Check.png" alt="COVID check" className="dgdg-covid-check" />
                <div className="dgdg-covid text-left">
                    <table cellPadding="5px">
                        <tbody>
                            <tr>
                                <td>Employer/Business/Entity Name:</td>
                                <td>
                                    <DGDGFormDropdownComponent itemKey="display_name" cssName="dgdg-covid-field" data={this.state.stores} value={this.state.selectedStore ? this.state.selectedStore.display_name : "Select"} onItemClick={this.onStoreClick} />
                                </td>
                                <td />
                            </tr>
                            <tr>
                                <td>Employee First Name:</td>
                                <td>
                                    <DGDGFormInputComponent id="first" cssName="dgdg-covid-field" value={this.state.first} onChange={this.onTextChange} />
                                </td>
                                <td />
                            </tr>
                            <tr>
                                <td>Employee Last Name:</td>
                                <td>
                                    <DGDGFormInputComponent id="last" cssName="dgdg-covid-field" value={this.state.last} onChange={this.onTextChange} />
                                </td>
                                <td />
                            </tr>
                            <tr>
                                <td>Date of Birth:</td>
                                <td>
                                    <DGDGFormCalendarComponent applicationInsights={this.props.applicationInsights} id="dob" value={this.state.dob} onDateChange={this.onDateChange}
                                        minDate={moment().subtract(100, "year").startOf("month").format("MM/DD/YYYY")} maxDate={moment().format("MM/DD/YYYY")} numberOfMonths={1} />
                                </td>
                                <td />
                            </tr>
                            <tr>
                                <td>Work Email:</td>
                                <td>
                                    <DGDGFormInputComponent id="workEmail" cssName="dgdg-covid-field" value={this.state.workEmail} onChange={this.onTextChange} placeholder="Enter your @dgdg.com email here" />
                                </td>
                                <td style={{ "fontSize": "13px" }}>(Enter your @dgdg.com email here)</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <p>Under the Health Officer’s May 18, 2021 Order, all businesses and governmental entities in Santa Clara County are required to determine the COVID-19 vaccination status of their employees (as well as contractors, volunteers, and others who regularly work onsite). In light of this requirement, you must provide the information requested below.</p>
                    <p>Please note that you are required to provide accurate information about your vaccination status in response to the questions below, or alternatively may decline to provide your vaccination status. If you decline to provide information about your vaccination status, we will be required to assume you are unvaccinated for purposes of rules or requirements in the workplace that are different for vaccinated or unvaccinated employees. For example, if requirements on face coverings allow fully vaccinated employees not to wear face coverings in certain settings, the information collected below will be used to determine whether you will be required to wear a face covering in those settings.</p>
                    <p>For purposes of this certification, you are considered “fully vaccinated” two weeks after completing the second dose of a two-dose COVID-19 vaccine (e.g., Pfizer or Moderna) or two weeks after receiving a single dose of a one-dose vaccine (e.g., Johnson & Johnson/Janssen).</p>
                    <table className="dgdg-covid-table" border="0px" cellSpacing="0px" cellPadding="0px">
                        <tbody>
                            <tr className="dgdg-covid-status-header">
                                <td colSpan="2" className="dgdg-no-border" style={{ "borderTopLeftRadius": "10px", "borderTopRightRadius": "10px" }}>Please select the statement below that accurately describes your vaccination status:</td>
                            </tr>
                            <tr className="dgdg-covid-status-body">
                                <td>
                                    <DGDGFormCheckboxComponent id="vaccinationStatus" value={this.state.vaccinationStatus === "Fully Vaccinated"} additionalData="Fully Vaccinated" onChange={this.onVaccinationStatus} />
                                </td>
                                <td>I am fully vaccinated.</td>
                            </tr>
                            <tr className="dgdg-covid-status-body">
                                <td>
                                    <DGDGFormCheckboxComponent id="vaccinationStatus" value={this.state.vaccinationStatus === "Received Second Dose"} additionalData="Received Second Dose" onChange={this.onVaccinationStatus} />
                                </td>
                                <td>I received my second dose of the Pfizer or Moderna vaccine or my single dose of a Johnson & Johnson vaccine less than two weeks ago.</td>
                            </tr>
                            <tr className="dgdg-covid-status-body">
                                <td>
                                    <DGDGFormCheckboxComponent id="vaccinationStatus" value={this.state.vaccinationStatus === "Received First Dose"} additionalData="Received First Dose" onChange={this.onVaccinationStatus} />
                                </td>
                                <td>I received my first dose of Moderna or Pfizer, and my second appointment is scheduled.</td>
                            </tr>
                            <tr className="dgdg-covid-status-body">
                                <td>
                                    <DGDGFormCheckboxComponent id="vaccinationStatus" value={this.state.vaccinationStatus === "Not Yet But Scheduled"} additionalData="Not Yet But Scheduled" onChange={this.onVaccinationStatus} />
                                </td>
                                <td>I have not yet been vaccinated, but I have already scheduled an appointment to receive my first dose of vaccine.</td>
                            </tr>
                            <tr className="dgdg-covid-status-body">
                                <td>
                                    <DGDGFormCheckboxComponent id="vaccinationStatus" value={this.state.vaccinationStatus === "Not Been Vaccinated"} additionalData="Not Been Vaccinated" onChange={this.onVaccinationStatus} />
                                </td>
                                <td>I have not been vaccinated.</td>
                            </tr>
                            <tr className="dgdg-covid-status-body">
                                <td style={{ "borderBottomLeftRadius": "10px" }}>
                                    <DGDGFormCheckboxComponent id="vaccinationStatus" value={this.state.vaccinationStatus === "Decline to Answer"} additionalData="Decline to Answer" onChange={this.onVaccinationStatus} />
                                </td>
                                <td style={{ "borderBottomRightRadius": "10px" }}>I decline to answer whether I have been vaccinated.</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <p>I understand that I am required to provide accurate information in response to the question above. I hereby affirm that I have accurately and truthfully answered the question above. I also understand that if I stated that I am fully vaccinated, my employer may request documentation of my vaccination status (e.g., a copy of my vaccine card or other similar official document confirming vaccination status).</p>
                    <table cellPadding="5px">
                        <tbody>
                            <tr>
                                <td>Signature:</td>
                                <td>
                                    <DGDGFormInputComponent id="signed" cssName="dgdg-covid-field" value={this.state.signed} onChange={this.onTextChange} placeholder="Type your full name here" />
                                </td>
                                <td style={{ "fontSize": "13px" }}>(Type your full name here)</td>
                            </tr>
                            <tr>
                                <td>Date:</td>
                                <td>
                                    <DGDGFormInputComponent id="signed" cssName="dgdg-covid-field" value={this.state.signedDate.format("MM/DD/YYYY")} disabled readOnly />
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="form-inline">
                                    <button disabled={this.state.showSpinner} className="btn btn-primary" onClick={(event) => this.onSaveClick(event)}>Submit</button>
                                    <DGDGSpinnerComponent showSpinner={this.state.showSpinner} />
                                </td>
                                <td colSpan="2">
                                    {
                                        this.state.showError
                                            ? <label className="text-danger font-weight-bold" style={{ "fontSize": "14px" }}>Please complete all fields to submit the form.</label>
                                            : null
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <img src="images/Covid_Footer.png" alt="COVID check" className="dgdg-covid-footer" />
                </div>
            </center>
        </DGDGPageComponent>;
    }
}