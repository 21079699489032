import React, { Fragment } from "react";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";
import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

export class OPDSalesEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            volumeGoal1: "",
            volumeGoal2: "",
            volumeGoal3: "",
            closingRatioGoal1: "",
            closingRatioGoal2: "",
            closingRatioGoal3: "",
            closingRatioGoal4: "",
            employeeList: [],
            teamMembers: [],
            paintTable: false,
            selectedEmployee: null,
            deleteEmployee: null,
            dialogTitle: "OPD Sales"
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onEmployeeClick = this.onEmployeeClick.bind(this);
        this.onEmployeeColumnCallback = this.onEmployeeColumnCallback.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSaveEmployeeClick = this.onSaveEmployeeClick.bind(this);
        this.onConfirmationOkClick = this.onConfirmationOkClick.bind(this);
        this.onConfirmationCancelClick = this.onConfirmationCancelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if ((this.props.rowData !== null && this.props.rowData !== prevProps.rowData && this.props.employeeList)
                || (this.props.teamMembersTableData && this.props.teamMembersTableData !== prevProps.teamMembersTableData)) {

                let dialogTitle = "OPD Sales - " + this.props.rowData.store_web_name + " - " + this.props.rowData.team_name + "'s Team";

                this.setState({
                    id: this.props.rowData.opd_sales_team_id,
                    volumeGoal1: this.props.rowData.volume_goal_1,
                    volumeGoal2: this.props.rowData.volume_goal_2,
                    volumeGoal3: this.props.rowData.volume_goal_3,
                    closingRatioGoal1: this.props.rowData.closing_ratio_goal_1 ? CommonService.formatNumber_1(this.props.rowData.closing_ratio_goal_1 * 100) : "",
                    closingRatioGoal2: this.props.rowData.closing_ratio_goal_2 ? CommonService.formatNumber_1(this.props.rowData.closing_ratio_goal_2 * 100) : "",
                    closingRatioGoal3: this.props.rowData.closing_ratio_goal_3 ? CommonService.formatNumber_1(this.props.rowData.closing_ratio_goal_3 * 100) : "",
                    closingRatioGoal4: this.props.rowData.closing_ratio_goal_4 ? CommonService.formatNumber_1(this.props.rowData.closing_ratio_goal_4 * 100) : "",
                    dialogTitle: dialogTitle,
                    teamMembers: this.props.teamMembersTableData,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Edit OPD Sales";

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        try {
            this.props.onSaveClick(event, {
                id: this.props.rowData.opd_sales_team_id,
                storeNumber: this.props.rowData.store_number,
                opdEmployeeNumber: this.props.rowData.employee_number,
                volumeGoal1: this.state.volumeGoal1,
                volumeGoal2: this.state.volumeGoal2,
                volumeGoal3: this.state.volumeGoal3,
                closingRatioGoal1: this.state.closingRatioGoal1 / 100,
                closingRatioGoal2: this.state.closingRatioGoal2 / 100,
                closingRatioGoal3: this.state.closingRatioGoal3 / 100,
                closingRatioGoal4: this.state.closingRatioGoal4 / 100,
                employeeNumber: this.state.selectedEmployee ? this.state.selectedEmployee.employee_number : this.props.rowData.employee_number,
            });
            this.onCancelClick(event);

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveEmployeeClick(event) {
        try {
            this.props.onSaveEmployeeClick(event, this.props.rowData.store_number, {
                id: null,
                opdSalesTeamId: this.props.rowData.opd_sales_team_id,
                employeeNumber: this.state.selectedEmployee.employee_number
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                volumeGoal1: "",
                volumeGoal2: "",
                volumeGoal3: "",
                closingRatioGoal1: "",
                closingRatioGoal2: "",
                closingRatioGoal3: "",
                closingRatioGoal4: "",
                employeeList: null,
                selectedEmployee: null,
                teamMembers: [],
                dialogTitle: "Edit OPD Sales"
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteEmployeeClick(event, rowData) {
        try {
            this.setState({ deleteEmployee: rowData })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationOkClick(event) {
        try {
            this.props.onDeleteEmployeeClick(event, this.props.rowData.store_number, {
                id: this.state.deleteEmployee.id,
                opdSalesTeamId: this.state.deleteEmployee.opd_sales_team_id,
            })
            this.onConfirmationCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onConfirmationCancelClick(event) {
        try {
            this.setState({ deleteEmployee: null })
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("OPDSales", "onCopyTableCallBack");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onEmployeeColumnCallback(rowData) {
        return <Fragment>
            {
                rowData.employee_name === undefined && this.state.teamMembers.indexOf(rowData) === this.state.teamMembers.length - 1
                    ? <DGDGFormDropdownComponent data={this.props.employeeList} itemKey="employee_name" value={this.state.selectedEmployee ? this.state.selectedEmployee.employee_name : "Select Employee"} onItemClick={this.onEmployeeClick} />
                    : rowData.employee_name
            }
        </Fragment>
    }

    onEmployeeClick(id, employee) {
        try {
            this.setState({
                selectedEmployee: employee,
                paintTable: true
            }, () => {
                this.onSaveEmployeeClick();
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddClick(event) {
        try {
            let teamMembers = this.state.teamMembers;
            if (teamMembers.length === 0 || teamMembers[teamMembers.length - 1].employee_name !== undefined) {
                teamMembers.push({});
                this.setState({
                    teamMembers: teamMembers,
                    selectedEmployee: null,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(rowData) {
        return rowData.employee_name
            ? <button type="button" className="btn btn-link" onClick={(event) => this.onDeleteEmployeeClick(event, rowData)} >
                < span className="fas fa-window-close text-danger dgdg-font-size-18 dgdg-icon" aria-hidden="true" />
            </ button >
            : null
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-opd-sales-edit-dialog"
            title={this.state.dialogTitle}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText={"Remove Employee: " + (this.state.deleteEmployee?.employee_name)}
                bodyText={"Are you sure?"}
                showConfirmation={this.state.deleteEmployee !== null}
                onOkClick={this.onConfirmationOkClick}
                onCancelClick={this.onConfirmationCancelClick} />
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save  <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap dgdg-opd-sales-table">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue" colSpan="8" style={{ "paddingTop": "5px", "paddingBottom": "5px" }}>
                                        Qualifying Closing Ratio: 8.5%
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue dgdg-opd-sales-edit-volume-width" colSpan="8">
                                        Volume Goal
                                    </td>
                                </tr>
                                <tr style={{textIndent:"10px"}}>
                                    <td className="dgdg-font-blue" style={{textIndent:"20px"}}>
                                        Level 1:
                                    </td>
                                    <td className="dgdg-opd-sales-edit-volume-width">
                                        <DGDGFormInputComponent id="volumeGoal1" value={this.state.volumeGoal1} onChange={this.onTextChange} inputType="number" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Level 2:
                                    </td>
                                    <td className="dgdg-opd-sales-edit-volume-width">
                                        <DGDGFormInputComponent id="volumeGoal2" value={this.state.volumeGoal2} onChange={this.onTextChange} inputType="number" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Level 3:
                                    </td>
                                    <td className="dgdg-opd-sales-edit-volume-width">
                                        <DGDGFormInputComponent id="volumeGoal3" value={this.state.volumeGoal3} onChange={this.onTextChange} inputType="number" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue dgdg-opd-sales-edit-volume-width" colSpan="8">
                                        Closing Ratio Goal
                                    </td>
                                </tr>
                                <tr style={{textIndent:"10px"}}>
                                    <td className="dgdg-font-blue" style={{textIndent:"20px"}}>
                                        Level 1:
                                    </td>
                                    <td className="dgdg-opd-sales-edit-closing-width">
                                        <DGDGFormInputComponent id="closingRatioGoal1" value={this.state.closingRatioGoal1} onChange={this.onTextChange} inputType="percent_1" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Level 2:
                                    </td>
                                    <td className="dgdg-opd-sales-edit-closing-width">
                                        <DGDGFormInputComponent id="closingRatioGoal2" value={this.state.closingRatioGoal2} onChange={this.onTextChange} inputType="percent_1" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Level 3:
                                    </td>
                                    <td className="dgdg-opd-sales-edit-closing-width">
                                        <DGDGFormInputComponent id="closingRatioGoal3" value={this.state.closingRatioGoal3} onChange={this.onTextChange} inputType="percent_1" />
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Level 4:
                                    </td>
                                    <td className="dgdg-opd-sales-edit-closing-width">
                                        <DGDGFormInputComponent id="closingRatioGoal4" value={this.state.closingRatioGoal4} onChange={this.onTextChange} inputType="percent_1" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div className="card-block dgdg-opd-sales-add-employee-width">
                            <div className="dgdg-report-body">
                                <div className="card dgdg-card-gutter">
                                    <div className="card-header">
                                        <button className="btn btn-primary" onClick={(event) => this.onAddClick(event)} disabled={!this.props.rowData?.opd_sales_team_id}>
                                            Add <span className="fas fa-plus dgdg-icon" />
                                        </button>
                                    </div>
                                </div>
                                <DGDGTableV3Component cardCssName="dgdg-opd-sales-add-employee-table-width" applicationInsights={this.props.applicationInsights} tableData={this.state.teamMembers} paintTable={this.state.paintTable}>
                                    <DGDGTableRowComponent>
                                        <DGDGTableColumnComponent headerText="Employee Name" dataColumnCallback={this.onEmployeeColumnCallback} />
                                        <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} />
                                    </DGDGTableRowComponent>
                                </DGDGTableV3Component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent >;
    }
}
