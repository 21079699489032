import React from "react";
import ReactDOM from "react-dom";
import * as atlas from "azure-maps-control";

export class DGDGChartAzureMapComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        if (this.props && this.props.dashboardItem &&
            (this.props.azureSubscriptionKey)) {
            this.drawChart();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props && this.props.azureSubscriptionKey &&
                (!prevProps || JSON.stringify(prevProps) !== JSON.stringify(this.props))) {
                this.drawChart();
            }
        }
        catch (error) {
            console.devError(error);
        }
    }

    drawChart() {

        let addressText = [];
        let addressListLat = [];
        let addressListLon = [];
        let doNotClosePopup = false;

        if (this.props.dashboardItem !== null && this.props.dashboardItem !== undefined) {
            this.props.dashboardItem.data.forEach((responseItem, index) => {
                let url = this.props.azureGetAddressUrl + this.props.azureSubscriptionKey + "&api-version=1.0&lat&limit=1&query=" + responseItem.address;
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        addressText.push(responseItem.text);
                        addressListLat.push(data.results[0].position.lat);
                        addressListLon.push(data.results[0].position.lon);
                        if (addressListLat.length === this.props.dashboardItem.data.length - 1) {
                            let popupEvent = this.props.popuUpDisplay;
                            let popupAzureTemplate = '<div class="dgdg-azure-map-custom-info-box"><div class="name">{name}</div>{description}</div>';
                            let map = new atlas.Map(ReactDOM.findDOMNode(this), {
                                center: [addressListLon[0], addressListLat[0]],
                                zoom: 12,
                                language: 'en-US',
                                authOptions: {
                                    authType: 'subscriptionKey',
                                    subscriptionKey: this.props.azureSubscriptionKey,
                                },
                                showFeedbackLink: false,
                                showLogo: false,
                            });

                            map.events.add('ready', function () {
                                let dataSource = new atlas.source.DataSource();
                                map.sources.add(dataSource);
                                let layer = new atlas.layer.SymbolLayer(dataSource);
                                map.layers.add(layer);
                                for (let i = 0; i < addressListLat.length; i++) {
                                    dataSource.add(new atlas.data.Feature(new atlas.data.Point([addressListLon[i], addressListLat[i]]), {
                                        name: addressText[i],
                                        description: ''
                                    }));
                                }
                                let popup = new atlas.Popup({
                                    pixelOffset: [0, -18],
                                    closeButton: false
                                });
                                switch (popupEvent) {

                                    case "onHover":
                                        map.events.add('mouseover', layer, (e) => {
                                            if (e.shapes && e.shapes.length > 0) {
                                                let content, coordinate;
                                                let properties = e.shapes[0].getProperties();
                                                content = popupAzureTemplate.replace(/{name}/g, properties.name).replace(/{description}/g, properties.description);
                                                coordinate = e.shapes[0].getCoordinates();
                                                popup.setOptions({
                                                    content: content,
                                                    position: coordinate
                                                });
                                                popup.open(map);
                                            }
                                        });
                                        map.events.add('mouseleave', layer, () => {
                                            popup.close();
                                        });
                                        break;

                                    case "onClick":
                                        map.events.add('click', layer, (e) => {
                                            doNotClosePopup = true;
                                            if (e.shapes && e.shapes.length > 0) {
                                                let content, coordinate;
                                                if (e.shapes[0] instanceof atlas.Shape && e.shapes[0].getType() === 'Point') {
                                                    let properties = e.shapes[0].getProperties();
                                                    content = popupAzureTemplate.replace(/{name}/g, properties.name).replace(/{description}/g, properties.description);
                                                    coordinate = e.shapes[0].getCoordinates();
                                                }
                                                if (content && coordinate) {
                                                    popup.setOptions({
                                                        content: content,
                                                        position: coordinate
                                                    });
                                                    popup.open(map);
                                                }
                                            }
                                        });
                                        map.events.add('click', () => {
                                            if (doNotClosePopup === false) {
                                                popup.close();
                                            }
                                            doNotClosePopup = false;
                                        });
                                        break;

                                    default:
                                        break;
                                }
                            });
                        }
                    });
            });
        }
    }

    render() {
        return <div className="dgdg-widget-item" style={{ "width": "calc(100% - 15px)", "height": "calc(100% - 5px)" }} />;
    }
}