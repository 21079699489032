import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGFormDropdownComponent } from "../common/dgdgdropdown/DGDGFormDropdownComponent";
import { DGDGFormInputComponent } from "../common/dgdginput/DGDGFormInputComponent";
import { DGDGModalDialogComponent } from "../common/dgdgmodaldialog/DGDGModalDialogComponent";
import { DGDGModalDialogHeaderButtonComponent } from "../common/dgdgmodaldialog/DGDGModalDialogHeaderButtonComponent";

export class OPDSalesTeamResultEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            selectedVolumeBonusLevel: null,
            volumeBonus: "",
            selectedClosingRatioBonusLevel: null,
            closingRatioBonus: "",
            dialogTitle: "OPD Sales Team Result"
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onVolumeBonusLevelClick = this.onVolumeBonusLevelClick.bind(this);
        this.onClosingRatioBonusLevelClick = this.onClosingRatioBonusLevelClick.bind(this);

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.rowData !== null && this.props.rowData !== prevProps.rowData && this.props.editMonth != null) {
                let month = moment().month(this.props.editMonth).startOf("month").format("MMMM YYYY");
                let dialogTitle = "OPD Sales - " + this.props.rowData.store_web_name + " - " + this.props.rowData.team_name + "'s Team - " + month;

                let selectedVolumeBonusLevel = this.props.volumeOptions.filter(volumeOption => volumeOption.level === this.props.rowData[this.props.editMonth + "_volume_bonus_level"]);
                if (selectedVolumeBonusLevel) {
                    selectedVolumeBonusLevel = selectedVolumeBonusLevel[0]
                }

                let selectedClosingRatioBonusLevel = this.props.closingRatioOptions.filter(ClosingRatioBonusOption => ClosingRatioBonusOption.level === this.props.rowData[this.props.editMonth + "_closing_ratio_bonus_level"]);
                if (selectedClosingRatioBonusLevel) {
                    selectedClosingRatioBonusLevel = selectedClosingRatioBonusLevel[0]
                }

                this.setState({
                    id: this.props.rowData.opd_sales_team_id,
                    selectedVolumeBonusLevel: selectedVolumeBonusLevel,
                    volumeBonus: this.props.rowData[this.props.editMonth + "_volume_bonus"] ? CommonService.formatNumber(this.props.rowData[this.props.editMonth + "_volume_bonus"]) : "",
                    selectedClosingRatioBonusLevel: selectedClosingRatioBonusLevel,
                    closingRatioBonus: this.props.rowData[this.props.editMonth + "_closing_ratio_bonus"] ? CommonService.formatNumber(this.props.rowData[this.props.editMonth + "_closing_ratio_bonus"]) : "",
                    dialogTitle: dialogTitle,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    pageTitle = "Edit OPD Sales Team Result";

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event) {
        let selectedMonth = moment().month(this.props.editMonth).startOf("month").format("YYYY-MM-DDTHH:mm:ss");
        try {
            this.props.onSaveClick(event, {
                id: this.props.rowData.id,
                storeNumber: this.props.rowData.store_number,
                opdSalesTeamId: this.props.rowData.opd_sales_team_id,
                resultMonth: selectedMonth,
                volumeBonusLevel: this.state.selectedVolumeBonusLevel ? this.state.selectedVolumeBonusLevel.level : null,
                closingBonusLevel: this.state.selectedClosingRatioBonusLevel ? this.state.selectedClosingRatioBonusLevel.level : null
            });
            this.onCancelClick(event);

        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedVolumeBonusLevel: null,
                volumeBonus: "",
                selectedClosingRatioBonusLevel: null,
                closingRatioBonus: "",
                dialogTitle: "Edit OPD Sales Team Result"
            });

            this.props.onCancelClick(event);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onVolumeBonusLevelClick(id, selectedVolumeBonusLevel) {
        try {
            this.setState({
                selectedVolumeBonusLevel: selectedVolumeBonusLevel,
                volumeBonus: selectedVolumeBonusLevel.value
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onClosingRatioBonusLevelClick(id, selectedClosingRatioBonusLevel) {
        try {
            this.setState({
                selectedClosingRatioBonusLevel: selectedClosingRatioBonusLevel,
                closingRatioBonus: selectedClosingRatioBonusLevel.value
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("OPDSalesTeamResult", "onCopyTableCallBack");
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <DGDGModalDialogComponent
            applicationInsights={this.props.applicationInsights}
            showDialog={this.props.showDialog}
            cssName="dgdg-opd-sales-team-result-edit-dialog"
            title={this.state.dialogTitle}
            onCancelClick={this.onCancelClick}
        >
            <DGDGModalDialogHeaderButtonComponent>
                <button className="btn btn-primary" onClick={this.onSaveClick}>
                    Save  <i className="fas fa-save dgdg-icon" />
                </button>
            </DGDGModalDialogHeaderButtonComponent>
            <div className="dgdg-report-body">
                <div className="card">
                    <div className="card-block">
                        <table className="text-nowrap dgdg-opd-sales-table">
                            <tbody>
                                <tr>
                                    <td className="dgdg-font-blue" style={{ "width": "100px" }}>
                                        Volume Bonus Level:
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td style={{ "width": "100px" }}>
                                        <DGDGFormDropdownComponent data={this.props.volumeOptions} itemKey="level" value={this.state.selectedVolumeBonusLevel ? this.state.selectedVolumeBonusLevel.level : "Select"} onItemClick={this.onVolumeBonusLevelClick} />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue" style={{ "width": "100px" }}>
                                        Volume Bonus:
                                    </td>
                                    <td />
                                    <td style={{ "width": "150px" }}>
                                        <DGDGFormInputComponent id="volumeBonus" value={this.state.volumeBonus} onChange={this.onTextChange} inputType="money_0" readOnly />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td style={{ "width": "320px" }}>
                                        {
                                            this.state.selectedVolumeBonusLevel
                                                ? "(assumes Volume for the team is at or above " + this.state.selectedVolumeBonusLevel.goal + ")"
                                                : null
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="dgdg-font-blue">
                                        Closing Ratio Bonus Level:
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td>
                                        <DGDGFormDropdownComponent data={this.props.closingRatioOptions} itemKey="level" value={this.state.selectedClosingRatioBonusLevel ? this.state.selectedClosingRatioBonusLevel.level : "Select"} onItemClick={this.onClosingRatioBonusLevelClick} />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td className="dgdg-font-blue">
                                        Closing Ratio Bonus:
                                    </td>
                                    <td />
                                    <td>
                                        <DGDGFormInputComponent id="closingRatioBonus" value={this.state.closingRatioBonus} onChange={this.onTextChange} inputType="money_0" readOnly />
                                    </td>
                                    <td>&nbsp;&nbsp;</td>
                                    <td style={{ "width": "320px" }}>
                                        {
                                            this.state.selectedClosingRatioBonusLevel
                                                ? "(assumes Closing Ratio for the team is at or above " + CommonService.formatPercentage_1(this.state.selectedClosingRatioBonusLevel.goal) + ")"
                                                : null
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DGDGModalDialogComponent >;
    }
}