import React from "react";
import ReactDOM from "react-dom";
import * as atlas from "azure-maps-control";

export class DGDGChartAzureMapClusterAggregatesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        if (this.props && this.props.dashboardItem &&
            (this.props.azureSubscriptionKey)) {
            this.drawChart();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props && this.props.azureSubscriptionKey &&
                (!prevProps || JSON.stringify(prevProps) !== JSON.stringify(this.props))) {
                this.drawChart();
            }
        }
        catch (error) {
            console.devError(error);
        }
    }

    drawChart() {

        let addressText = [];
        let addressListLat = [];
        let addressListLon = [];
        let doNotClosePopup = false;
        let popup = new atlas.Popup();
        let entityTypes = this.props.entityTypes;
        let clusterProperties = null;
        let currentEntity = null;
        let bubbleLayerProperties = null;
        let azureTextOptions = null;
        let azureMapFilterOptions = null;

        if (this.props.dashboardItem !== null && this.props.dashboardItem !== undefined) {
            this.props.dashboardItem.data.forEach((responseItem, index) => {
                let url = this.props.azureGetAddressUrl + this.props.azureSubscriptionKey + "&api-version=1.0&lat&limit=1&query=" + responseItem.address;
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        addressText.push(responseItem.text);
                        addressListLat.push(data.results[0].position.lat);
                        addressListLon.push(data.results[0].position.lon);
                        clusterProperties = this.props.clusterProperties;
                        currentEntity = entityTypes[0];
                        bubbleLayerProperties = this.props.bubbleLayerProperties;
                        azureTextOptions = this.props.azureMapTextOptions;
                        azureMapFilterOptions = this.props.azureMapFilterOptions;
                        if (addressListLat.length === this.props.dashboardItem.data.length - 1) {
                            let map = new atlas.Map(ReactDOM.findDOMNode(this), {
                                center: [addressListLon[0], addressListLat[0]],
                                zoom: 3,
                                view: 'Auto',
                                authOptions: {
                                    authType: 'subscriptionKey',
                                    subscriptionKey: this.props.azureSubscriptionKey
                                }
                            });

                            map.events.add('ready', function () {

                                let datasource = new atlas.source.DataSource(null, {
                                    cluster: true,
                                    clusterRadius: 50,
                                    clusterProperties: clusterProperties
                                });
                                map.sources.add(datasource);
                                let clusterBubbleLayer = new atlas.layer.BubbleLayer(datasource, null, bubbleLayerProperties);
                                map.events.add('click', clusterBubbleLayer, clusterClicked);

                                map.layers.add([
                                    clusterBubbleLayer,
                                    new atlas.layer.SymbolLayer(datasource, null, {
                                        iconOptions: {
                                            image: 'none'
                                        },
                                        textOptions: azureTextOptions
                                    }),
                                    new atlas.layer.SymbolLayer(datasource, null, {
                                        filter: azureMapFilterOptions
                                    })
                                ]);

                                for (let i = 0; i < addressListLat.length; i++) {
                                    datasource.add(new atlas.data.Feature(new atlas.data.Point([addressListLon[i], addressListLat[i]]), {
                                        name: addressText[i],
                                        description: '',
                                        EntityType: currentEntity
                                    }));
                                }

                            });

                            map.events.add('click', () => {
                                if (doNotClosePopup === false) {
                                    popup.close();
                                }
                                doNotClosePopup = false;
                            });

                            function clusterClicked(e) {
                                if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
                                    let cluster = e.shapes[0];
                                    let html = ['<div style="padding:10px;">'];
                                    html.push(`<b>Cluster size: ${cluster.properties.point_count_abbreviated} entities</b><br/><br/>`);
                                    entityTypes.forEach(et => {
                                        html.push(`${et}: ${cluster.properties[et]}<br/>`);
                                    })
                                    html.push('</div>');
                                    popup.setOptions({
                                        position: cluster.geometry.coordinates,
                                        content: html.join('')
                                    });
                                    popup.open(map);
                                }
                            }
                        }
                    });
            });
        }
    }

    render() {
        return <div className="dgdg-widget-item" style={{ "width": "calc(100% - 15px)", "height": "calc(100% - 5px)" }} />;
    }
}
