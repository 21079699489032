import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/dgdgjs/CommonService";

import { DGDGModalConfirmationComponent } from "../common/dgdgmodalconfirmation/DGDGModalConfirmationComponent";
import { DGDGTableColumnComponent } from "../common/dgdgtable/DGDGTableColumnComponent";
import { DGDGTableRowComponent } from "../common/dgdgtable/DGDGTableRowComponent";
import { DGDGTableV3Component } from "../common/dgdgtable/DGDGTableV3Component";

import { FrontEndAdjustmentQueryComponent } from "./FrontEndAdjustmentQueryComponent";
import { FrontEndAdjustmentAddComponent } from "./FrontEndAdjustmentAddComponent";

export class FrontEndAdjustmentComponent extends React.Component {
    static shouldEnableAction(selectedMonth) {
        selectedMonth = moment(selectedMonth);
        let isActionEnabled = true;
        let minCutoffDate = moment().startOf("month").subtract(1, "month");
        let prevMonthCutoffDate = moment().startOf("month").add(9, "day");
        let currentDate = moment();
        if (selectedMonth < minCutoffDate
            || (selectedMonth.month() === currentDate.month() - 1 && currentDate > prevMonthCutoffDate)) {
            isActionEnabled = false;
        }

        return isActionEnabled;
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            selectedMonth: null,
            showDialog: false,
            paintTable: false,
            tableTitle: "",
            tableData: [],
            isActionEnabled: false,
            selectedRow: null,
            accountNumbers: null,
            frontendAdjustmentId: null,
            stockNumber: null,
            adjustmentDate: null,
            reasons: null,
            canEdit: false,
        };

        this.onMonthClick = this.onMonthClick.bind(this);
        this.onGetDetailsClick = this.onGetDetailsClick.bind(this);
        this.onAddFrontEndAdjustmentClick = this.onAddFrontEndAdjustmentClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onActionDeleteClick = this.onActionDeleteClick.bind(this);
        this.onDeleteFrontEndAdjustmentOkClick = this.onDeleteFrontEndAdjustmentOkClick.bind(this);
        this.onDeleteFrontEndAdjustmentCancelClick = this.onDeleteFrontEndAdjustmentCancelClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);

        props.onBreadCrumbStateChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            console.devLog("FrontEndAdjustmentComponent - componentDidMount");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/FrontEndAdjustments/FrontEndAdjustments", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getFrontEndAdjustmentAccount();
            this.getComments();
            CommonService.clientAzureStorageLog("FrontEndAdjustmentComponent", "FrontEndAdjustmentComponent", "componentDidMount", null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    componentWillUnmount() {
        this.props.onLastUpdatedDateChanged("");
    }

    pageTitle = "Front-end Adjustments";

    getFrontEndAdjustmentAccount() {
        this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentComponent/getFrontEndAdjustmentAccount", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/FrontEndAdjustments/GetFrontEndAdjustmentAccount")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                parsedResponse = JSON.parse(parsedResponse);
                parsedResponse.Accounts.forEach((responseItem, index) => {
                    responseItem.display_name = (responseItem.AccountNumber + " - " + responseItem.Description)
                });

                this.setState({ accountNumbers: parsedResponse.Accounts });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetFrontEndAdjustmentAccount", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    getComments() {
        this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentComponent/getComments", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/FrontEndAdjustments/GetFrontEndAdjustmentsComments")
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({ comments: parsedResponse });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetFrontEndAdjustmentsComments", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.devError(jsonParseError, null, this.props.applicationInsights);
                        });
                }
            });
    }

    onMonthClick(event, selectedMonth) {
        try {
            this.setState({ selectedMonth: selectedMonth });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionColumnCallback(selectedRow) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, selectedRow)} disabled={!selectedRow.can_edit}>
                <i className="fas fa-edit" />
            </button>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionDeleteClick(event, selectedRow)} disabled={!selectedRow.can_edit}>
                <i className="fas fa-times text-danger dgdg-icon" />
            </button>
        </Fragment>;
    }

    onActionEditClick(event, selectedRow) {
        try {
            this.setState({
                selectedRow: selectedRow,
                showDialog: true
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onActionDeleteClick(event, selectedRow) {
        try {
            this.setState({
                frontEndAdjustmentId: selectedRow.id,
                stockNumber: selectedRow.stock_number,
                adjustmentDate: CommonService.formatDate(moment(selectedRow.adjustment_date))
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetDetailsClick(selectedMonth) {
        try {
            this.setState({
                isLoadingData: true,
                selectedMonth: selectedMonth
            });

            let inputData = { startDate: CommonService.formatServerDateTime(selectedMonth) };
            this.onGetLastUpdatedDate(selectedMonth);

            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentComponent/onGetDetailsClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetFrontEndAdjustmentsDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (this.state.accountNumbers) {
                        parsedResponse.data.forEach((responseItem, index) => {
                            let accountNumberDetail = this.state.accountNumbers.filter(accountRow => {
                                return responseItem.account_number === accountRow.AccountNumber;
                            });
                            responseItem.account_number = accountNumberDetail.length > 0 ? accountNumberDetail[0].display_name : null;
                        });
                    }

                    this.setState({
                        isLoadingData: false,
                        isActionEnabled: FrontEndAdjustmentComponent.shouldEnableAction(selectedMonth),
                        tableTitle: "Front-end Adjustments - " + CommonService.formatMonthYear(moment(selectedMonth)),
                        tableData: CommonService.addOrdinalColumn(parsedResponse.data),
                        canEdit: parsedResponse.canEdit,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetFrontEndAdjustmentsDetails", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onGetLastUpdatedDate(selectedMonth) {
        try {
            let inputData = { month: CommonService.formatServerDateTime(selectedMonth) };
            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentComponent/onGetLastUpdatedDate", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/GetLastUpdated", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (CommonService.isDateValid(parsedResponse)) {
                        this.props.onLastUpdatedDateChanged(moment.utc(parsedResponse));
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/GetLastUpdated", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onAddFrontEndAdjustmentClick(event) {
        try {
            this.setState({ showDialog: true });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onSaveClick(event, frontEndAdjustments) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = frontEndAdjustments;
            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentComponent/onSaveClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/SaveFrontEndAdjustment", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ isLoadingData: false });
                    this.onGetDetailsClick(this.state.selectedMonth);
                    this.props.onShowAlert("success", { message: "Front-End Adjustment saved" }, 4000);
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/SaveFrontEndAdjustment", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteFrontEndAdjustmentOkClick(event) {
        try {
            this.setState({ showSpinner: true });
            let inputData = {
                id: this.state.frontEndAdjustmentId
            };
            this.props.applicationInsights.trackTrace({ message: "/FrontEndAdjustmentComponent/onDeleteFrontEndAdjustmentOkClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/FrontEndAdjustments/DeleteFrontEndAdjustment", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({ showSpinner: false });
                    this.onGetDetailsClick(this.state.selectedMonth);
                    this.onDeleteFrontEndAdjustmentCancelClick(event);
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/FrontEndAdjustments/DeleteFrontEndAdjustment", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.devError(notOKResponse, parsedError, this.props.applicationInsights);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.devError(jsonParseError, null, this.props.applicationInsights);
                            });
                    }
                });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onDeleteFrontEndAdjustmentCancelClick(event) {
        try {
            this.setState({
                frontEndAdjustmentId: null,
                stockNumber: null,
                adjustmentDate: null
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCancelClick(event) {
        try {
            this.setState({
                selectedRow: null,
                showDialog: false
            });
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("FrontEndAdjustmentComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }
    }

    render() {
        return <div className="dgdg-report-body dgdg-flex dgdg-flex-column">
            <FrontEndAdjustmentAddComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showDialog}
                title={this.state.tableTitle}
                stores={this.props.stores}
                accountNumbers={this.state.accountNumbers}
                comments={this.state.comments}
                lastUpdatedBy={this.props.lastUpdatedBy}
                rowData={this.state.selectedRow}
                adjustmentDate={this.state.selectedMonth}
                onSaveClick={this.onSaveClick}
                onCancelClick={this.onCancelClick}
                onShowAlert={this.props.onShowAlert}
            />
            <DGDGModalConfirmationComponent
                applicationInsights={this.props.applicationInsights}
                headerText="Delete Front-end Adjustment"
                bodyText={
                    this.state.stockNumber
                        ? "Confirm delete of Front-end Adjustment on " + this.state.adjustmentDate + " for Stock Number " + this.state.stockNumber + "?"
                        : "Confirm delete of Front-end Adjustment on " + this.state.adjustmentDate + "?"
                }
                showConfirmation={this.state.frontEndAdjustmentId}
                onOkClick={this.onDeleteFrontEndAdjustmentOkClick}
                onCancelClick={this.onDeleteFrontEndAdjustmentCancelClick}
            />
            <FrontEndAdjustmentQueryComponent
                applicationInsights={this.props.applicationInsights}
                canEdit={this.state.canEdit}
                isLoadingData={this.state.isLoadingData}
                months={this.props.months}
                onMonthClick={this.onMonthClick}
                onGetDetailsClick={this.onGetDetailsClick}
                onAddFrontEndAdjustmentClick={this.onAddFrontEndAdjustmentClick}
            />
            <div className="dgdg-site-scrollable-content">
                <DGDGTableV3Component applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                    copyTableConfig={{ "columns": this.state.isActionEnabled ? [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    {
                        this.state.isActionEnabled
                            ? <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} cssName="text-nowrap" />
                                <DGDGTableColumnComponent headerText="Adjustment Date" dataColumn="adjustment_date" sortColumn="adjustment_date" filterColumn="adjustment_date" dataType="date" />
                                <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" />
                                <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" />
                                <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="date" />
                                <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" />
                                <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" />
                                <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                                <DGDGTableColumnComponent headerText="New/Used/Wholesale" dataColumn="new_used_wholesale" sortColumn="new_used_wholesale" filterColumn="new_used_wholesale" />
                                <DGDGTableColumnComponent headerText="Account Number" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" />
                                <DGDGTableColumnComponent headerText="Adjust Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                                <DGDGTableColumnComponent headerText="Reason" dataColumn="reason" sortColumn="reason" filterColumn="reason" />
                                <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" sortColumn="comments" filterColumn="comments" />
                                <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" />
                            </DGDGTableRowComponent>
                            : <DGDGTableRowComponent>
                                <DGDGTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                                <DGDGTableColumnComponent headerText="Adjustment Date" dataColumn="adjustment_date" sortColumn="adjustment_date" filterColumn="adjustment_date" dataType="date" />
                                <DGDGTableColumnComponent headerText="Store Name" dataColumn="store_name_web" sortColumn="store_number" filterColumn="store_name_web" />
                                <DGDGTableColumnComponent headerText="Deal Number" dataColumn="deal_number" sortColumn="deal_number" filterColumn="deal_number" />
                                <DGDGTableColumnComponent headerText="Deal Date" dataColumn="deal_date" sortColumn="deal_date" filterColumn="deal_date" dataType="date" />
                                <DGDGTableColumnComponent headerText="Customer Number" dataColumn="customer_number" sortColumn="customer_number" filterColumn="customer_number" />
                                <DGDGTableColumnComponent headerText="Customer Name" dataColumn="customer_name" sortColumn="customer_name" filterColumn="customer_name" />
                                <DGDGTableColumnComponent headerText="Stock Number" dataColumn="stock_number" sortColumn="stock_number" filterColumn="stock_number" />
                                <DGDGTableColumnComponent headerText="New/Used/Wholesale" dataColumn="new_used_wholesale" sortColumn="new_used_wholesale" filterColumn="new_used_wholesale" />
                                <DGDGTableColumnComponent headerText="Account Number" dataColumn="account_number" sortColumn="account_number" filterColumn="account_number" />
                                <DGDGTableColumnComponent headerText="Amount" dataColumn="amount" sortColumn="amount" filterColumn="amount" dataType="money_2" />
                                <DGDGTableColumnComponent headerText="Adjust Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                                <DGDGTableColumnComponent headerText="Reason" dataColumn="reason" sortColumn="reason" filterColumn="reason" />
                                <DGDGTableColumnComponent headerText="Comments" dataColumn="comments" sortColumn="comments" filterColumn="comments" />
                                <DGDGTableColumnComponent headerText="Last Updated By" dataColumn="last_updated_by" sortColumn="last_updated_by" filterColumn="last_updated_by" />
                            </DGDGTableRowComponent>
                    }
                </DGDGTableV3Component>
            </div>
        </div>;
    }
}
