import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

export class DGDGLeftBarComponent extends React.Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (props.leftBarState === "Expanded" && state.activeMenuId === "Collapsed") {
                newState.activeMenuId = state.lastActiveMenuId;
            }

            if (window.location.pathname !== state.pathName) {
                newState.pathName = window.location.pathname;
            }
        }
        catch (error) {
            console.devError(error, null, this.props.applicationInsights);
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            activeMenuId: "",
            lastActiveMenuId: "menuItem-0",
            pathName: window.location.pathname
        };

        this.onLeftBarStateChanged = this.onLeftBarStateChanged.bind(this);
        this.onMenuTitleClick = this.onMenuTitleClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
    }

    onLeftBarStateChanged(event, lftBarState) {
        if (JSON.stringify(this.props.leftBarState) !== JSON.stringify(lftBarState)) {
            this.setState({ activeMenuId: "Collapsed" });
            if (this.props.onLeftBarStateChanged) {
                this.props.onLeftBarStateChanged(event, lftBarState);
            }
        }
    }

    onMenuTitleClick(event, id) {
        $('#mobileMenu').addClass('show');
        if (this.state.activeMenuId === id) {
            this.setState({
                activeMenuId: "Collapsed",
                lastActiveMenuId: "Collapsed"
            });
        } else {
            this.setState({
                activeMenuId: id,
                lastActiveMenuId: id
            });
        }
    }

    onMenuItemClick(event, link) {
        $('#mobileMenu').removeClass('show');
        let activeMenuId = this.props.leftBarState === "Collapsed" ? "Collapsed" : this.state.activeMenuId;
        this.setState({
            activeMenuId: activeMenuId,
            pathName: link
        });
    }

    createMenu(menuItems) {
        let menu = [];
        if (menuItems) {
            menuItems.forEach((menuItem, index) => {
                menu.push(<li key={"menuItem-" + index} className={"nav-item" + (this.isMenuActive(menuItem) ? " active" : "")}>
                    {
                        menuItem.childMenuItems
                            ? <Fragment>
                                <button key="menu" type="button" className="btn btn-link nav-link collapsed" data-toggle="collapse" data-target=".nav-collapse.in" onClick={(event) => this.onMenuTitleClick(event, "menuItem-" + index)}>
                                    <i className={"sidebar-menu-icon " + menuItem.iconCssName} />
                                    <span>{menuItem.title}</span>
                                </button>
                                <ul key="sub-menu" className={"nav flex-column collapse" + (this.canShowMenu(menuItem.childMenuItems, index) ? " show" : "")}>
                                    {this.createMenu(menuItem.childMenuItems)}
                                </ul>
                            </Fragment>
                            : <Link className="nav-link" to={menuItem.href} onClick={(event) => this.onMenuItemClick(event, menuItem.href)}>
                                {
                                    menuItem.iconCssName
                                        ? <i className={"sidebar-menu-icon " + menuItem.iconCssName} />
                                        : null
                                }
                                <span>{menuItem.title}</span>
                            </Link>
                    }
                </li>);
            });
        }

        return menu;
    }

    canShowMenu(menuItems, menuIndex) {
        if (this.state.activeMenuId === "menuItem-" + menuIndex) {
            return true;
        }
        else if (this.state.activeMenuId === "") {
            let isActive = false;
            menuItems.forEach((menuItem, index) => {
                if (this.isMenuActive(menuItem)) {
                    isActive = true;
                }
            });

            return isActive;
        }

        return false;
    }

    isMenuActive(menuItem) {
        if (menuItem.href) {
            if (this.state.pathName === menuItem.href
                || (this.state.pathName === "/" && menuItem.href === "/Dashboard")) {
                return true;
            }
            else {
                return this.state.pathName.split('/')[1] === menuItem.href.split('/')[1];
            }
        }

        return false;
    }

    render() {
        return <div className={"sidebar" + (this.props.leftBarState === "Collapsed" ? " active" : "")}>
            <div className="text-center dgdg-logo dgdg-desktop">
                {
                    this.props.leftBarState === "Expanded"
                        ? <Link to="/" onClick={(event) => this.onMenuItemClick(event, "/")}>
                            <img src="images/dgdg_plate.png" style={{ height: "50px", padding: "4px 3px 0px 3px" }} alt="DGDG" />
                        </Link>
                        : <Link to="/" onClick={(event) => this.onMenuItemClick(event, "/")}>
                            <img src="images/dgdg favico.png" style={{ padding: "4px 3px 0px 3px" }} alt="DGDG" />
                        </Link>
                }
            </div>
            {
                this.props.showLeftBar
                    ? <Fragment>
                        <ul className="nav flex-column dgdg-desktop">
                            <li className="nav-item">
                                <button type="button" className="btn btn-link text-right" style={{ padding: "0px" }} onClick={(event) => this.onLeftBarStateChanged(event, this.props.leftBarState === "Collapsed" ? "Expanded" : "Collapsed")}>
                                    {
                                        this.props.leftBarState === "Collapsed"
                                            ? <i className="far fa-chevron-double-right" style={{ paddingRight: "10px" }} />
                                            : <i className="far fa-chevron-double-left" />
                                    }
                                </button>
                            </li>
                        </ul>
                        <ul className="nav flex-column" style={{ paddingBottom: "41px" }}>
                            {this.createMenu(this.props.menuItems)}
                        </ul>
                    </Fragment>
                    : null
            }
        </div>;
    }
}